import './index.scss';
import './_icons';

import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider, SessionManager } from './_useAuth';
import { AlertProvider, AlertModal, ConfirmModal } from './_useAlert';
import { HelmetProvider } from 'react-helmet-async';
import MyRoutes from './_routes';

ReactDOM.render(
    <React.StrictMode>
        <HelmetProvider>
            <AuthProvider>
                <AlertProvider>
                    <BrowserRouter>
                        <MyRoutes />
                        <SessionManager />
                        <AlertModal />
                        <ConfirmModal />
                    </BrowserRouter>
                </AlertProvider>
            </AuthProvider>
        </HelmetProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


/*
Bootstrap component import cheat sheet

import {
    Navbar, Nav, NavDropdown,
    Container, Row, Col,
    Button, Form, FloatingLabel
} from 'react-bootstrap';

*/