import * as React from 'react';
import useAuth from '../../_useAuth';
import { useAlert } from '../../_useAlert';
import { DefaultUserPhoto } from '../../_defaultUserPhoto';
import {
    Container, Row, Col,
    Button, Form, FloatingLabel
} from 'react-bootstrap';
import PhotoModal from './photoModal';

export default function MyAccount() {
    const { curUser, updateLogin } = useAuth();
    const { alert, confirm } = useAlert();
    const [editUser, setEditUser] = React.useState({...curUser});
    const [showPhotoModal, setShowPhotoModal] = React.useState(false);
    
    function openPhotoModal() {
        setShowPhotoModal(true);
    }

    function closePhotoModal() {
        setShowPhotoModal(false);
    }

    function savePhoto(imageData) {
        fetch('/api/users/' + curUser.id, { 
            method: "PATCH" ,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "photo": imageData || ""
            })
        }).then(
            (res) => {
                if(!res.ok) {
                    throw new Error(res.status + " " + res.statusText);
                } else {
                    return true;
                }
            }
        ).then(
            (success) => {
                updateLogin();
                closePhotoModal();
            },
            (error) => {
                console.log(error);
                alert("Something went wrong",
                    <Row>
                        <Col>
                            Unable to update pass photo. Please refresh the page or try again later.
                        </Col>
                    </Row>,
                    () => {}
                );
            }
        );
    }

    function handleSubmit(e) {
        e.preventDefault();
        
        fetch('/api/users/' + curUser.id, { 
            method: "PATCH" ,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "firstName": editUser.firstName,
                "middleName": editUser.middleName || "",
                "lastName": editUser.lastName,
                "email": editUser.email || "",
                "phone": editUser.phone || "",
                "username": editUser.username
            })
        }).then(
            (res) => {
                if(!res.ok) {
                    throw new Error(res.status + " " + res.statusText);
                } else {
                    return true;
                }
            }
        ).then(
            (success) => {
                alert("Success",
                    <Row>
                        <Col>
                            Your account has been updated.
                        </Col>
                    </Row>,
                    () => { updateLogin(); }
                );
            },
            (error) => {
                console.log(error);
                alert("Something went wrong",
                    <Row>
                        <Col>
                            Unable to update account. Please refresh the page or try again later.
                        </Col>
                    </Row>,
                    () => {}
                );
            }
        );
    }

    function handleInput(e) {
        var key = e.target.getAttribute('name');
        var val = e.target.value;

        setEditUser({
            ...editUser,
            [key]: val
        });
    }

    function handleChangePassword() {
        confirm("Change Password",
            <>
                <Row>
                    <Col xs={12} lg={6}>
                        <FloatingLabel controlId="new-password" label="New Password">
                            <Form.Control type="password" placeholder="New Password" />
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} lg={6}>
                        <FloatingLabel controlId="confirm-new-password" label="Confirm New Password">
                            <Form.Control type="password" placeholder="Confirm New Password" />
                        </FloatingLabel>
                    </Col>
                </Row>
            </>,
            () => {
                const newPass = document.getElementById("new-password").value;
                const confirmNewPass = document.getElementById("confirm-new-password").value;
                if(!newPass || !confirmNewPass || newPass !== confirmNewPass) {
                    window.alert("Both passwords must match!");
                    return;
                }

                fetch('/api/users/' + curUser.id + "/updatepassword", { 
                    method: "POST" ,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "password": newPass.replace(/\s/g,'')
                    })
                }).then(
                    (res) => {
                        if(!res.ok) {
                            throw new Error(res.status + " " + res.statusText);
                        } else {
                            return true;
                        }
                    }
                ).then(
                    (success) => {
                        alert("Success", <Row><Col>Your password has been updated.</Col></Row>, () => {});
                    },
                    (error) => {
                        console.log(error);
                        alert("Something went wrong", <Row><Col>Unable to update password. Please refresh the page or try again later.</Col></Row>, () => {});
                    }
                );
            },
            () => {}
        );
    }

    React.useEffect(() => {
        setEditUser({...curUser});
    }, [curUser]);

    return (<>
        <Container fluid>
            <Row className="justify-content-center my-5">
                <Col xs="auto">
                    <h1>My Account</h1>
                </Col>
            </Row>
            <Row className="justify-content-center my-5">
                <Col xs="auto">
                    <img src={editUser?.photo || DefaultUserPhoto} height="100" />
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xs={12} sm={10} md={8} xl={6}>
                    <Form onSubmit={handleSubmit}>
                        <Row className="my-3">
                            <Col xs={12} lg className="mb-2 mb-lg-0">
                                <FloatingLabel controlId="editUserFirst" label="First">
                                    <Form.Control type="text" name="firstName" placeholder="First" required value={editUser?.firstName || ""} onChange={handleInput} />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} lg className="mb-2 mb-lg-0">
                                <FloatingLabel controlId="editUserMiddle" label="Middle">
                                    <Form.Control type="text" name="middleName" placeholder="Middle" value={editUser?.middleName || ""} onChange={handleInput} />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} lg>
                                <FloatingLabel controlId="editUserLast" label="Last">
                                    <Form.Control type="text" name="lastName" placeholder="Last" required value={editUser?.lastName || ""} onChange={handleInput} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col xs={12} lg className="mb-2 mb-lg-0">
                                <FloatingLabel controlId="editUserEmail" label="Email">
                                    <Form.Control type="email" name="email" placeholder="Email" value={editUser?.email || ""} onChange={handleInput} />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} lg>
                                <FloatingLabel controlId="editUserPhone" label="Phone">
                                    <Form.Control type="phone" name="phone" placeholder="Phone" value={editUser?.phone || ""} onChange={handleInput} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className={{"mb-5": editUser?.id, "mt-5 mb-3 align-items-center": true}}>
                            <Col xs={12} lg className="mb-2 mb-lg-0">
                                <FloatingLabel controlId="editUserUsername" label="Username">
                                    <Form.Control type="text" required name="username" placeholder="Username" value={editUser?.username || ""} onChange={handleInput} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        {editUser?.id && <Row className="mb-5 justify-content-evenly">
                            <Col xs={5}>
                                <div className="d-grid">
                                    <Button type="button" variant="outline-secondary" size="lg" onClick={handleChangePassword}>Change Password</Button>
                                </div>
                            </Col>
                            <Col xs={5}>
                                <div className="d-grid">
                                    <Button type="button" variant="outline-secondary" size="lg" onClick={openPhotoModal}>Choose Pass Photo</Button>
                                </div>
                            </Col>
                        </Row>}
                        <Row className="justify-content-center my-3">
                            <Col sm={6} md={5} xl={4}>
                                <div className="d-grid">
                                    <Button size="lg" type="submit">Save</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <PhotoModal 
                show={showPhotoModal}
                close={closePhotoModal}
                savePhoto={savePhoto}
            />
        </Container>
    </>);
}