// FontAwesome Icons (Global)
import { library } from '@fortawesome/fontawesome-svg-core';

import { 
    faUser as farUser,
    faSquarePlus as farSquarePlus,
    faCircle as farCircle,
    faFloppyDisk as farFloppyDisk
} from '@fortawesome/free-regular-svg-icons';

import {
    faUser as fasUser,
    faCircleInfo as fasCircleInfo,
    faFileExport as fasFileExport,
    faEllipsis as fasEllipsis,
    faArrowRightArrowLeft as fasArrowRightArrowLeft,
    faArrowRightFromBracket as fasArrowRightFromBracket,
    faArrowRightToBracket as fasArrowRightToBracket,
    faTable as fasTable,
    faGear as fasGear,
    faGears as fasGears,
    faCalendarCheck as fasCalendarCheck,
    faUsersGear as fasUsersGear,
    faTicket as fasTicket,
    faSkull as fasSkull,
    faMapPin as fasMapPin,
    faListCheck as fasListCheck,
    faAngleLeft as fasAngleLeft,
} from '@fortawesome/free-solid-svg-icons';

import {
    faFacebookF as fasFacebookF,
    faInstagram as fasInstagram
} from '@fortawesome/free-brands-svg-icons';

library.add( 
    farUser,
    fasUser,
    farSquarePlus,
    fasCircleInfo,
    fasFileExport,
    fasEllipsis,
    fasArrowRightArrowLeft,
    fasArrowRightFromBracket,
    fasArrowRightToBracket,
    fasTable,
    fasGear,
    fasGears,
    fasCalendarCheck,
    fasUsersGear,
    fasTicket,
    fasSkull,
    fasMapPin,
    farCircle,
    fasFacebookF,
    fasInstagram,
    fasListCheck,
    fasAngleLeft,
    farFloppyDisk
);