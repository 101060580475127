import * as React from 'react';
import {
    Container, Row, Col, Button, Modal, Form, FloatingLabel, Dropdown
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useHelpers from '../../../_useHelpers';
import { useAuth } from '../../../_useAuth';
import { useAlert } from '../../../_useAlert';
import { DefaultUserPhoto } from '../../../_defaultUserPhoto';
import TimesheetModal from './timesheetModal';
import PrintBadges from './printBadges';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import PhotoModal from '../photoModal';

export default function Employees() {
    const { formatCurrency } = useHelpers();
    const { curUser, curFest } = useAuth();
    const { alert, confirm } = useAlert();

    const [allUsers, setAllUsers] = React.useState([]);
    const [clockedUsers, setClockedUsers] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [filteredUsers, setFilteredUsers] = React.useState([]);
    const [events, setEvents] = React.useState([]);
    const [filter, setFilter] = React.useState("");
    const [statusFilter, setStatusFilter] = React.useState("active");
    const [eventFilter, setEventFilter] = React.useState("");
    const [positionFilter, setPositionFilter] = React.useState("");
    const [showEdit, setShowEdit] = React.useState(false);
    const [showTimesheet, setShowTimesheet] = React.useState(false);
    const [showBadges, setShowBadges] = React.useState(false);
    const [editUser, setEditUser] = React.useState(null);
    const [userBadges, setUserBadges] = React.useState([]);
    const [showPhotoModal, setShowPhotoModal] = React.useState(false);
    const [showAppModal, setShowAppModal] = React.useState(false);
    const [app, setApp] = React.useState(null);

    const refreshEventsPositions = React.useCallback(function() {
        fetch('/api/events?' + new URLSearchParams({"filter": JSON.stringify({"include":["positions"]})}), { method: "GET" })
        .then((res) => {if(!res.ok) {throw new Error(res.status + " " + res.statusText);} else {return res.json();}})
        .then((result) => {setEvents(result);},(error) => {console.log(error);});
    }, []);

    const refreshUsers = React.useCallback(function(cb) {
        fetch('/api/users?' + new URLSearchParams({"filter": JSON.stringify({"include":[{"relation": "positions", "scope": {"include": [{"relation": "event"}]}}, "userPositions"]})}), { method: "GET" }).then(
            (res) => {
                if(!res.ok) {
                    throw new Error(res.status + " " + res.statusText);
                } else {
                    return res.json();
                }
            }
        ).then(
            (result) => {
                setAllUsers(result);
                setUsers(result.filter(function(user) {
                    return user.isActive;
                }));

                fetch('/api/timeclock?' + new URLSearchParams({"filter": JSON.stringify({"where": { "endTime":  {"eq": null} }, "include": [{"relation": "user", "scope": {"include": [{"relation": "positions", "scope": {"include": [{"relation": "event"}]}},{"relation": "userPositions"}]}}]})}), { method: "GET" }).then((res) => { 
                    if(!res.ok) { 
                        throw new Error(res.status + " " + res.statusText); 
                    } else { 
                        return res.json(); 
                    } 
                })
                .then((result2) => {
                    var cUsers = [];
                    result2.forEach(function(timesheet) {
                        cUsers.push({...timesheet.user, timesheetId: timesheet.id, startTime: timesheet.startTime});
                    });
                    setClockedUsers(cUsers);
                    cb && cb(result);
                }, (error) => { 
                    console.log(error);
                    alert("Something went wrong",
                        <Row>
                            <Col>
                                Unable to load users. Please refresh the page or try again later.
                            </Col>
                        </Row>,
                        () => {}
                    );
                });
            },
            (error) => {
                console.log(error);
                alert("Something went wrong",
                    <Row>
                        <Col>
                            Unable to load users. Please refresh the page or try again later.
                        </Col>
                    </Row>,
                    () => {}
                );
            }
        );
    }, [alert]);

    const updateFilter = React.useCallback(function(e) {
        var f = filter;
        if(e) {
            setFilter(e.target.value);
            f = e.target.value;
        }

        const attrs = [
            "firstName",
            "middleName",
            "lastName",
            "email",
            "username",
            "paychexId"
        ];

        setFilteredUsers(users.filter(function(user) {
            var found = false;
            attrs.forEach(function(attr) {
                if(typeof user[attr] === 'string' && user[attr].toLowerCase().indexOf(f.toLowerCase()) !== -1) {
                    found = true;
                }
            });

            return found;
        }));
    }, [users, filter]);

    React.useEffect(() => {
        refreshEventsPositions();
        refreshUsers();
    }, [refreshUsers, refreshEventsPositions]);

    React.useEffect(() => {
        updateFilter();
    }, [updateFilter, users]);

    function openEditModal(user) {
        setEditUser(user);
        setShowEdit(true);
    }

    function openTimesheetModal(user) {
        setEditUser(user);
        setShowTimesheet(true);
    }
    
    function openBadgeModal(users) {
        setUserBadges(users);
        setShowBadges(true);
    }

    function openPhotoModal(user) {
        setEditUser(user);
        setShowPhotoModal(true);
    }

    function closePhotoModal() {
        setEditUser(null);
        setShowPhotoModal(false);
    }

    function closeEditModal() {
        setEditUser(null);
        setShowEdit(false);
    }

    function closeTimesheetModal() {
        setEditUser(null);
        setShowTimesheet(false);
        refreshUsers();
    }

    function closeBadgeModal() {
        setUserBadges([]);
        setShowBadges(false);
    }

    function closeAppModal() {
        setApp(null);
        setShowAppModal(false);
    }

    function saveUser(e) {
        e.preventDefault();

        if(!(editUser && editUser?.id)) {
            createUser();
            return false;
        }
        
        fetch('/api/users/' + editUser.id, { 
            method: "PATCH" ,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "firstName": editUser.firstName?.trim(),
                "middleName": editUser.middleName?.trim() || "",
                "lastName": editUser.lastName?.trim(),
                "email": editUser.email?.trim() || "",
                "phone": editUser.phone?.trim() || "",
                "paychexId": editUser.paychexId?.trim() || "000",
                "accessLvl": parseInt(editUser.accessLvl) || 0,
                "username": editUser.username?.trim()
            })
        }).then(
            (res) => {
                if(!res.ok) {
                    if(res.status === 422) {
                        return res.json();
                    } else {
                        throw new Error(res.status + " " + res.statusText);
                    }
                } else {
                    return true;
                }
            }
        ).then(
            (result) => {
                if(result.error) {
                    alert("Something went wrong",
                        <Row>
                            <Col>
                                {result.error.message}
                            </Col>
                        </Row>,
                        () => {}
                    );
                } else {
                    refreshUsers();
                    setEditUser(null);
                    setShowEdit(false);
                }
            },
            (error) => {
                console.log(error);
                alert("Something went wrong",
                    <Row>
                        <Col>
                            Unable to save employee. Please refresh the page or try again later.
                        </Col>
                    </Row>,
                    () => {}
                );
            }
        );

        return false;
    }

    function createUser() {
        if(!editUser) {
            alert("Something went wrong",
                <Row>
                    <Col>
                        Unable to add employee. Please refresh the page or try again later.
                    </Col>
                </Row>,
                () => {}
            );
            return;
        }

        fetch('/api/users/signup', { 
            method: "POST" ,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "firstName": editUser.firstName?.trim(),
                "middleName": editUser.middleName?.trim() || "",
                "lastName": editUser.lastName?.trim(),
                "email": editUser.email?.trim() || "",
                "phone": editUser.phone?.trim() || "",
                "paychexId": editUser.paychexId?.trim() || "000",
                "accessLvl": parseInt(editUser.accessLvl) || 0,
                "username": editUser.username?.trim(),
                "festivalId": curFest?.id
            })
        }).then(
            (res) => {
                if(!res.ok) {
                    if(res.status === 403) {
                        return res.json();
                    } else {
                        throw new Error(res.status + " " + res.statusText);
                    }
                } else {
                    return res.json();
                }
            }
        ).then(
            (result) => {
                if(result.error) {
                    alert("Something went wrong",
                        <Row>
                            <Col>
                                {result.error.message}
                            </Col>
                        </Row>,
                        () => {}
                    );
                } else {
                    refreshUsers((u) => {
                        setEditUser({...u.find(user => user.id === result.id)});
                        setShowEdit(true);
                        alert("Employee Added",
                            <Row>
                                <Col>
                                    You may now assign them positions and they will be availble in the badge and timeclock systems.
                                </Col>
                            </Row>,
                            () => {}
                        );
                    });
                }
            },
            (error) => {
                console.log(error);
                alert("Something went wrong",
                    <Row>
                        <Col>
                            Unable to add employee. Please refresh the page or try again later.
                        </Col>
                    </Row>,
                    () => {}
                );
            }
        );
    }

    function preAddPosition() {
        if(!(editUser && editUser?.id)) {
            alert("Something went wrong",
                <Row>
                    <Col>
                        Unable to add position. Please refresh the page or try again later.
                    </Col>
                </Row>,
                () => {}
            );
            return;
        }

        confirm("Make a selection",
            <Row>
                <Col>
                    <FloatingLabel controlId="newPosition" label="Position">
                        <Form.Select aria-label="New Position to Add" name="newPosition" onChange={(e) => {
                            document.querySelector('input[name="newPosPayrate"]').value = e.target.selectedOptions[0].dataset.defaultpayrate;
                        }}>
                            <option>Select One...</option>
                            {events.length && events.map(function(event) {
                                return (
                                    <optgroup key={event.id} label={event.name}>
                                        {event.positions?.length && event.positions.map(function(position) {
                                            return (
                                                <option key={position.id} value={position.id} data-defaultpayrate={position.defaultPayRate}>{position.name}</option>
                                            );
                                        })}
                                    </optgroup>
                                );
                            })}
                        </Form.Select>
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel controlId="newPosPayrate" label="Payrate">
                        <Form.Control type="number" step="0.25" required name="newPosPayrate" placeholder="Payrate" />
                    </FloatingLabel>
                </Col>
            </Row>,
            () => {
                addPosition(
                    editUser.id,
                    document.querySelector('select[name="newPosition"]').value,
                    document.querySelector('input[name="newPosPayrate"]').value
                );
            },
            () => {}
        );
    }

    function addPosition(userId, positionId, payrate) {
        fetch('/api/users/' + userId + '/addposition', { 
            method: "POST" ,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "positionId": parseInt(positionId),
                "payrate": parseFloat(payrate)
            })
        }).then(
            (res) => {
                if(!res.ok) {
                    throw new Error(res.status + " " + res.statusText);
                } else {
                    return true;
                }
            }
        ).then(
            (success) => {
                refreshUsers((u) => {
                    setEditUser({...u.find(user => user.id === userId)});
                });
            },
            (error) => {
                console.log(error);
                alert("Something went wrong",
                    <Row>
                        <Col>
                            Unable add position. Please refresh the page or try again later.
                        </Col>
                    </Row>,
                    () => {}
                );
            }
        );

        return false;
    }

    function removePosition(userId, positionId, payrate) {
        fetch('/api/users/' + userId + '/removeposition', { 
            method: "POST" ,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "positionId": parseInt(positionId),
                "payrate": parseFloat(payrate)
            })
        }).then(
            (res) => {
                if(!res.ok) {
                    throw new Error(res.status + " " + res.statusText);
                } else {
                    return true;
                }
            }
        ).then(
            (success) => {
                refreshUsers((u) => {
                    setEditUser({...u.find(user => user.id === userId)});
                });
            },
            (error) => {
                console.log(error);
                alert("Something went wrong",
                    <Row>
                        <Col>
                            Unable remove position. Please refresh the page or try again later.
                        </Col>
                    </Row>,
                    () => {}
                );
            }
        );

        return false;
    }

    function resetPassword(u) {
        fetch('/api/users/' + u.id + "/resetpassword", {method: "POST"})
        .then(
            (res) => {
                if(!res.ok) {
                    throw new Error(res.status + " " + res.statusText);
                } else {
                    return true;
                }
            }
        ).then(
            (success) => {
                alert("Success", <Row><Col>Password has been reset to [<span className="fw-bold">{(u.firstName.toLowerCase() + u.lastName.toLowerCase()).replace(/\s/g,'')}</span>].</Col></Row>, () => {});
            },
            (error) => {
                console.log(error);
                alert("Something went wrong", <Row><Col>Unable to reset password. Please refresh the page or try again later.</Col></Row>, () => {});
            }
        );
    }

    function deactivate() {
        confirm("Are you sure you want to deactivate this employee?",
            <Row>
                <Col>
                    They will no longer be able to clock in or out and will not be able to login. Existing hours in the timeclock will be preserved and can still appear in timesheets and exports.
                </Col>
            </Row>,
            () => {
                fetch('/api/users/' + editUser.id, { 
                    method: "PATCH" ,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "isActive": false
                    })
                }).then(
                    (res) => {
                        if(!res.ok) {
                            throw new Error(res.status + " " + res.statusText);
                        } else {
                            return true;
                        }
                    }
                ).then(
                    (success) => {
                        refreshUsers();
                        setEditUser(null);
                        setShowEdit(false);
                    },
                    (error) => {
                        console.log(error);
                        alert("Something went wrong", <Row><Col> Unable to deactivate employee. Please refresh the page or try again later.</Col></Row>, () => {});
                    }
                );
            },
            () => {}
        );
    }

    function reactivate() {
        confirm("Are you sure you want to reactivate this employee?",
            <Row>
                <Col>
                    They will be able to clock in and out and will be able to login again.
                </Col>
            </Row>,
            () => {
                fetch('/api/users/' + editUser.id, { 
                    method: "PATCH" ,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "isActive": true
                    })
                }).then(
                    (res) => {
                        if(!res.ok) {
                            throw new Error(res.status + " " + res.statusText);
                        } else {
                            return true;
                        }
                    }
                ).then(
                    (success) => {
                        refreshUsers();
                        setEditUser(null);
                        setShowEdit(false);
                    },
                    (error) => {
                        console.log(error);
                        alert("Something went wrong", <Row><Col> Unable to reactivate employee. Please refresh the page or try again later.</Col></Row>, () => {});
                    }
                );
            },
            () => {}
        );
    }

    function handleInput(e) {
        var key = e.target.getAttribute('name');
        var val = e.target.value;

        setEditUser({
            ...editUser,
            [key]: val
        });
    }

    function updateStatusFilter(e) {
        setStatusFilter(e.target.value);
    }

    function updateEventFilter(e) {
        setEventFilter(e.target.value);
    }

    function updatePositionFilter(e) {
        setPositionFilter(e.target.value);
    }

    function clockOutAll(userIds) {
        var success = true;
        userIds.forEach(async (id) => {
            var user = clockedUsers.find(cu => cu.id === id);
            if(user) {
                if(await clockOut(user) === false) {
                    success = false;
                }
            }
        });

        if(success) {
            refreshUsers();
        } else {
            alert("Something went wrong",
                <Row><Col>Unable to clockout employees. Please refresh the page or try again later.</Col></Row>,
                () => {}
            );
        }
    }

    async function clockOutOne(userId) {
        var user = clockedUsers.find(cu => cu.id === userId);
        if(user) {
            if(await clockOut(user) === false) {
                alert("Something went wrong",
                    <Row><Col>Unable to clockout employee. Please refresh the page or try again later.</Col></Row>,
                    () => {}
                );
            } else {
                refreshUsers();
            }
        }
    }

    function clockOut(clockedUser) {
        if(clockedUser.timesheetId) {
            fetch('/api/timeclock/' + clockedUser.timesheetId, { 
                method: "PATCH" ,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "endTime": formatInTimeZone(new Date(), 'GMT', "yyyy-MM-dd'T'HH:mm:ss.SSSX")
                })
            }).then(
                (res) => {if(!res.ok) {throw new Error(res.status + " " + res.statusText);} else {return true;}}
            ).then(
                (success) => {
                    return true;
                },
                (error) => {
                    console.log(error);
                    return false;
                }
            );
        }
    }

    React.useEffect(() => {
        var filteredUsers = [];

        if(statusFilter === "active") {
            filteredUsers = allUsers.filter(function(user) {
                return user.isActive;
            });
        } else if(statusFilter === "clocked") {
            filteredUsers = clockedUsers;
        } else if(statusFilter === "inactive") {
            filteredUsers = allUsers.filter(function(user) {
                return !user.isActive;
            });
        }

        if(eventFilter) {
            filteredUsers = filteredUsers.filter(function(user) {
                var found = false;
                user.positions?.forEach(function(pos) {
                    if(pos.eventId === parseInt(eventFilter)) {
                        found = true;
                    }
                });
                return found;
            });
        }
        
        if(positionFilter) {
            filteredUsers = filteredUsers.filter(function(user) {
                var found = false;
                user.positions?.forEach(function(pos) {
                    if(pos.id === parseInt(positionFilter)) {
                        found = true;
                    }
                });
                return found;
            });
        }

        setUsers(filteredUsers);
    }, [statusFilter, eventFilter, positionFilter, allUsers, clockedUsers]);

    function savePhoto(imageData) {
        fetch('/api/users/' + editUser.id, { 
            method: "PATCH" ,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "photo": imageData || ""
            })
        }).then(
            (res) => {
                if(!res.ok) {
                    throw new Error(res.status + " " + res.statusText);
                } else {
                    return true;
                }
            }
        ).then(
            (success) => {
                refreshUsers();
                closePhotoModal();
            },
            (error) => {
                console.log(error);
                alert("Something went wrong",
                    <Row>
                        <Col>
                            Unable to update pass photo. Please refresh the page or try again later.
                        </Col>
                    </Row>,
                    () => {}
                );
            }
        );
    }

    function findApplication(userId) {
        fetch('/api/applications?' + new URLSearchParams({"filter": JSON.stringify({"where":{"userId": userId}})}), { method: "GET" })
        .then(
            (res) => { if(!res.ok) { throw new Error(res.status + " " + res.statusText); } else { return res.json(); } }
        ).then(
            (result) => {
                if(Array.isArray(result) && result.length === 0) {
                    alert("Not Found",
                        <Row>
                            <Col>
                                No application found for this employee.
                            </Col>
                        </Row>,
                        () => {}
                    );
                } else if(Array.isArray(result) && result.length === 1) {
                    let app = result[0];
                    let data = JSON.parse(app.rawData);
                    setApp({
                        name: (data.firstName + " " + data.middleName + " " + data.lastName),
                        positionId: parseInt(data.position),
                        over16: data.over16,
                        returning: data.returning,
                        limitations: data.limitations?.length || 0,
                        unavailable: data.unavailable?.length || 0,
                        data: data,
                        ...app
                    });
                    setShowAppModal(true);
                } else {
                    alert("Something went wrong",
                        <Row>
                            <Col>
                                Unexpected application list returned for user...
                            </Col>
                        </Row>,
                        () => {}
                    );
                }
            },
            (error) => {
                console.log(error);
                alert("Something went wrong",
                    <Row>
                        <Col>
                            Unable to find an application for this user.
                        </Col>
                    </Row>,
                    () => {}
                );
            }
        );
    }

    return (<>
        <Row className="my-3">
            <Col>
                <h1>Employees</h1>
            </Col>
        </Row>
        <Row className='justify-content-center my-3'>
            <Col>
                <FloatingLabel controlId="userStatusFilter" label="Status">
                    <Form.Select aria-label="Employee Status Filter" defaultValue="active" onChange={updateStatusFilter}>
                        <option value="active">Active</option>
                        <option value="clocked">Clocked In</option>
                        <option value="inactive">Inactive</option>
                    </Form.Select>
                </FloatingLabel>
            </Col>
            <Col>
                <FloatingLabel controlId="userEventFilter" label="Event">
                    <Form.Select aria-label="Event Filter" onChange={updateEventFilter}>
                        <option value="">All Events</option>
                        {events.length && events.map(function(event) {
                            return (
                                <option key={event.id} value={event.id}>{event.name}</option>
                            );
                        })}
                    </Form.Select>
                </FloatingLabel>
            </Col>
            <Col>
                <FloatingLabel controlId="userPositionFilter" label="Position">
                    <Form.Select aria-label="Position Filter" onChange={updatePositionFilter}>
                        <option value="">All Positions</option>
                        {events.length && events.map(function(event) {
                            if(!eventFilter || eventFilter === "" || eventFilter === event.id.toString()) {
                                return (
                                    <optgroup key={event.id} label={event.name}>
                                        {event.positions?.length && event.positions.map(function(position) {
                                            return (
                                                <option key={position.id} value={position.id}>{position.name}</option>
                                            );
                                        })}
                                    </optgroup>
                                );
                            } else {
                                return(<React.Fragment key={event.id}></React.Fragment>);
                            }
                        })}
                    </Form.Select>
                </FloatingLabel>
            </Col>
            <Col xs={12} lg={5} xxl={6} className="mt-3 mt-lg-0">
                <FloatingLabel controlId="usersFilter" label="Filter">
                    <Form.Control type="text" name="filter" placeholder="Filter" value={filter} onChange={updateFilter} />
                </FloatingLabel>
            </Col>
        </Row>
        <Row className="divider dotted"></Row>
        {statusFilter !== "inactive" &&
            <Row className="justify-content-center justify-content-lg-end mt-5">
                <Col xs={4} lg={3} xl={2}>
                    <div className="d-grid">
                        <Button variant="outline-primary" onClick={() => openBadgeModal(filteredUsers)}>All Badges</Button>
                    </div>
                </Col>
                <Col xs={4} lg={3} xl={2}>
                    <div className="d-grid">
                        <Button variant="outline-danger" onClick={() => clockOutAll(filteredUsers.map(u => u.id))}>Clock Out All</Button>
                    </div>
                </Col>
            </Row>
        }
        <Row className='my-3'>
            {(statusFilter === "active" && curUser.accessLvl >= 2) &&
                <Col xxl={3} lg={4} md={6} xs={12} className="my-3">
                    <div className="d-grid cell p-2">
                        <Button variant="link" type="button" className="text-decoration-none" onClick={() => { openEditModal({}) }}>
                            <div className="mb-5">
                                <Row>
                                    <Col className="text-center">
                                        <span className="fs-xhuge fw-bold text-success">+</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center">
                                        <span className="fs-5 text-success fw-bold">Add Employee</span>
                                    </Col>
                                </Row>
                            </div>
                        </Button>
                    </div>
                </Col>
            }
            {filteredUsers.length ? filteredUsers.map(function(user, index) {
                return(
                    <Col xxl={3} lg={4} md={6} xs={12} key={index} className="my-3">
                        <div className={`cell p-2 ${!user.isActive && 'bg-danger bg-opacity-10'}`}>
                            <Row className="mt-1 mb-3">
                                <Col xs="auto">
                                    <img src={user.photo || DefaultUserPhoto} height="50" className="rounded" />
                                </Col>
                                <Col className="align-self-center">
                                    <h3>{user.firstName} {user.middleName} {user.lastName}</h3>
                                </Col>
                                <Col xs="auto" className="align-self-top">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="link" className="no-arrow">
                                            <FontAwesomeIcon icon="fa-solid fa-ellipsis" size="xl" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => { openEditModal({...user}) }}>Edit</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { openPhotoModal({...user}) }}>Edit Photo</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { openBadgeModal([{...user}]) }}>Badge</Dropdown.Item>
                                            {curFest?.enableApps && <Dropdown.Item onClick={() => { findApplication(user.id) }}>Application</Dropdown.Item>}
                                            <Dropdown.Item onClick={() => { openTimesheetModal({...user}) }}>Timesheet</Dropdown.Item>
                                            {clockedUsers.some(cu => cu.id === user.id) &&
                                                <Dropdown.Item onClick={() => { clockOutOne(user.id) }}>Clock Out</Dropdown.Item>
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                            {clockedUsers.some(cu => cu.id === user.id) &&
                                <Row className="bg-danger bg-opacity-50 my-4 fw-bold py-2">
                                    <Col xs={4}>
                                        Clocked In:
                                    </Col>
                                    <Col>
                                        {format(new Date(clockedUsers.find(cu => cu.id === user.id)?.startTime), 'M/d/yyyy h:mm:ss a')}
                                    </Col>
                                </Row>
                            }
                            <Row className="my-1">
                                <Col xs={4}>
                                    ID (Paychex ID):
                                </Col>
                                <Col>
                                    {user.id} ({user.paychexId || '000'})
                                </Col>
                            </Row>
                            <Row className="my-1">
                                <Col xs={4}>
                                    Username:
                                </Col>
                                <Col>
                                    {user.username}
                                </Col>
                            </Row>
                            <Row className="my-1">
                                <Col xs={4}>
                                    Email: 
                                </Col>
                                <Col>
                                    {user.email}
                                </Col>
                            </Row>
                            <Row className="my-1">
                                <Col xs={4}>
                                    Phone:
                                </Col>
                                <Col>
                                    {user.phone}
                                </Col>
                            </Row>
                            <Row className="my-1">
                                <Col xs={4}>
                                    Access Level:
                                </Col>
                                <Col>
                                    {user.accessLvl}
                                </Col>
                            </Row>
                            <Row className="my-1">
                                <Col xs={4}>
                                    Position(s):
                                </Col>
                                <Col>
                                    {user?.userPositions?.length && user.userPositions.map(function(up, index) {
                                        var pos = user.positions.find(position => position.id === up.positionId);
                                        return (<span key={index}>
                                            {pos.event.name + " - " + pos.name + (up.payrate ? " (" + formatCurrency(up.payrate) + "/hr)" : "")}<br/>
                                        </span>);
                                    })}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                );
            }) : <></>}
        </Row>
        <Modal
            show={showEdit}
            size="lg"
            aria-labelledby="edit-user-modal-title"
            backdrop="static"
            backdropClassName="session"
            className="session"
        >
            <Form onSubmit={saveUser}>
                <Modal.Header>
                    <Modal.Title id="edit-user-modal-title">
                        {editUser?.id ? <>Edit Employee</> : <>Add Employee</>}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row className="my-3">
                            <Col xs={12} lg className="mb-2 mb-lg-0">
                                <FloatingLabel controlId="editUserFirst" label="First">
                                    <Form.Control type="text" name="firstName" placeholder="First" required value={editUser?.firstName || ""} onChange={handleInput} />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} lg className="mb-2 mb-lg-0">
                                <FloatingLabel controlId="editUserMiddle" label="Middle">
                                    <Form.Control type="text" name="middleName" placeholder="Middle" value={editUser?.middleName || ""} onChange={handleInput} />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} lg>
                                <FloatingLabel controlId="editUserLast" label="Last">
                                    <Form.Control type="text" name="lastName" placeholder="Last" required value={editUser?.lastName || ""} onChange={handleInput} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col xs={12} lg className="mb-2 mb-lg-0">
                                <FloatingLabel controlId="editUserEmail" label="Email">
                                    <Form.Control type="email" name="email" placeholder="Email" value={editUser?.email || ""} onChange={handleInput} />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} lg>
                                <FloatingLabel controlId="editUserPhone" label="Phone">
                                    <Form.Control type="phone" name="phone" placeholder="Phone" value={editUser?.phone || ""} onChange={handleInput} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="my-3">
                            {(curUser.accessLvl >= 2) && <>
                                <Col xs={12} lg className="mb-2 mb-lg-0">
                                    <FloatingLabel controlId="editUserPaychexId" label="Paychex ID">
                                        <Form.Control type="text" name="paychexId" placeholder="Paychex ID" value={editUser?.paychexId || ""} onChange={handleInput} />
                                    </FloatingLabel>
                                </Col>
                            </>}
                            {(curUser.accessLvl >= 3) && <>
                                <Col xs={12} lg>
                                    <FloatingLabel controlId="editUserAccess" label="Access Level (0-3)">
                                        <Form.Control type="number" min="0" max="3" required name="accessLvl" placeholder="Access Level (0-3)" value={editUser?.accessLvl} onChange={handleInput} />
                                    </FloatingLabel>
                                </Col>
                            </>}
                        </Row>
                        <Row className={{"my-5 align-items-center": true}}>
                            <Col xs={12} lg className="mb-2 mb-lg-0">
                                <FloatingLabel controlId="editUserUsername" label="Username">
                                    <Form.Control type="text" required name="username" placeholder="Username" value={editUser?.username || ""} onChange={handleInput} />
                                </FloatingLabel>
                            </Col>
                            {editUser?.id && <>
                                <Col xs={12} lg>
                                    <div className="d-grid">
                                        <Button type="button" variant="outline-secondary" size="lg" onClick={() => {resetPassword(editUser)}}>Reset Password</Button>
                                    </div>
                                </Col>
                            </>}
                        </Row>
                        {editUser?.id && <>
                            <Row>
                                <Col>
                                    <h4>Position(s):</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {editUser?.userPositions?.length && editUser.userPositions.map(function(up, index) {
                                        var pos = editUser.positions.find(position => position.id === up.positionId);
                                        if(curUser.accessLvl >= 2) {
                                            return (<React.Fragment key={up.id}>
                                                <Row className="align-items-center justify-content-evenly my-3">
                                                    <Col xs="auto">
                                                        <span className="text-capitalize">{pos.event.name} - {pos.name}</span>
                                                    </Col>
                                                    <Col xs="auto">
                                                        <Form.Control id={"payrate" + up.id} type="number" step="0.25" required name={"payrate" + up.id} placeholder="Payrate" defaultValue={up.payrate} />
                                                    </Col>
                                                    <Col xs="auto">
                                                        <Button type="button" variant="outline-danger" size="sm" onClick={() => {removePosition(editUser.id, pos.id, up.payrate)}}>Remove</Button>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>);
                                        } else {
                                            return (<React.Fragment key={up.id}>
                                                <Row className="align-items-center my-3">
                                                    <Col xs="auto">
                                                        <span className="text-capitalize">{pos.event.name} - {pos.name}</span>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>);
                                        }
                                    })}
                                    {(curUser.accessLvl >= 2) && <>
                                        <Row className="justify-content-center my-3">
                                            <Col xs={12} lg={8}>
                                                <div className="d-grid">
                                                    <Button type="button" variant="outline-primary" onClick={preAddPosition}>+ Add Position</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>}
                                </Col>
                            </Row>
                        </>}
                    </Container>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <div>
                        {editUser?.id && <>
                            {editUser?.isActive ?
                                <Button type="button" variant="danger" onClick={deactivate}>Deactivate Employee</Button>
                            :
                                <Button type="button" variant="success" onClick={reactivate}>Reactivate Employee</Button>
                            }
                        </>}
                    </div>
                    <div>
                        <Button variant="primary" type="submit" className="me-2">{editUser?.id ? "Save" : "Add"}</Button>
                        <Button variant="secondary" onClick={closeEditModal}>Cancel</Button>
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
        <Modal
            show={showBadges}
            size="lg"
            aria-labelledby="badges-modal-title"
            backdropClassName="session"
            className="session"
            onHide={closeBadgeModal}
        >
            <Modal.Header closeButton>
                <Modal.Title id="badges-modal-title">
                    Employee Badges
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PrintBadges users={userBadges} />
            </Modal.Body>
        </Modal>
        <Modal
            show={showAppModal}
            size="lg"
            aria-labelledby="app-modal-title"
            backdropClassName="session"
            className="session"
            onHide={closeAppModal}
        >
            <Modal.Header closeButton>
                <Modal.Title id="app-modal-title">
                    Application
                </Modal.Title>
            </Modal.Header>
            {app !== null && <Modal.Body>
                <Col className="mb-3">
                        <Row className="mt-1 mb-3">
                            <Col className="align-self-center">
                                <h3>{app?.name}</h3>
                            </Col>
                        </Row>
                        <div className="my-3">
                            {!app?.over16 && <Row className="bg-danger bg-opacity-25 my-1 fw-bold py-2">
                                <Col>
                                    16 or Under
                                </Col>
                            </Row>}
                            {app?.unavailable === 0 && <Row className="bg-success bg-opacity-25 my-1 fw-bold py-2">
                                <Col>
                                    Full Availability
                                </Col>
                            </Row>}
                            {app?.unavailable > 0 && app?.unavailable < 5 && <Row className="bg-warning bg-opacity-25 my-1 fw-bold py-2">
                                <Col>
                                    Good Availability
                                </Col>
                            </Row>}
                            {app?.unavailable > 4 && <Row className="bg-danger bg-opacity-25 my-1 fw-bold py-2">
                                <Col>
                                    Some Availability
                                </Col>
                            </Row>}
                            {app?.limitations === 0 && <Row className="bg-success bg-opacity-25 my-1 fw-bold py-2">
                                <Col>
                                    No Limitiations
                                </Col>
                            </Row>}
                            {app?.limitations > 0 && <Row className="bg-warning bg-opacity-25 my-1 fw-bold py-2">
                                <Col>
                                    Some Limitiations
                                </Col>
                            </Row>}
                        </div>
                        <Row className="my-3">
                            <Col>
                                <Row>
                                    <Col>
                                        <Form.Label>Management Notes:</Form.Label>
                                    </Col>
                                </Row>
                                <Form.Control disabled as="textarea" placeholder="Notes" style={{ height: '150px' }} defaultValue={app?.notes || ""} />
                            </Col>
                        </Row>
                        {Object.keys(app?.data).map((key) => {
                            return (
                                <Row key={key + app?.id} className="my-3 me-1">
                                    <Col xs={4}>
                                        {key}:
                                    </Col>
                                    <Col className="border rounded bg-light">
                                        {Array.isArray(app?.data[key]) ? 
                                                app?.data[key].map((val, i) => { 
                                                    return (<pre className="mb-0 text-prewrap" key={key + app?.id + "_" + i}>{val}</pre>);
                                                }) 
                                            : 
                                                <pre className="mb-0 text-prewrap">{app?.data[key].toString()}</pre>
                                        }
                                    </Col>
                                </Row>
                            );
                        })}
                </Col>
            </Modal.Body>}
        </Modal>
        {showTimesheet && <TimesheetModal show={showTimesheet} user={editUser} close={closeTimesheetModal} />}
        <PhotoModal show={showPhotoModal} close={closePhotoModal} savePhoto={savePhoto} />
    </>);
}