import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Container, Row, Col, Button, Form, FloatingLabel
} from 'react-bootstrap';
import { useAlert } from '../../_useAlert';

export default function Apply(props) {
    const navigate = useNavigate();
    const { alert, confirm } = useAlert();

    const [openingDate, setOpeningDate] = React.useState(new Date("Sep 13, 2024 19:00:00"));
    const [runningWeeks, setRunningWeeks] = React.useState(7);
    const [extraSaturdays, setExtraSaturdays] = React.useState(1);
    const [positions, setPositions] = React.useState([]);
    const [validated, setValidated] = React.useState(false);

    const [position, setPosition] = React.useState("");
    const [firstName, setFirstName] = React.useState("");
    const [middleName, setMiddleName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [street, setStreet] = React.useState("");
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [zip, setZip] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [over16, setOver16] = React.useState(true);
    const [returning, setReturning] = React.useState(false);
    const [returningNotes, setReturningNotes] = React.useState("");
    const [otherNotes, setOtherNotes] = React.useState("");
    const [unavailable, setUnavailable] = React.useState([]);
    const [limitations, setLimitations] = React.useState([]);

    React.useEffect(function() {
        fetch('/api/publicAppPosition/' + props.fest, { method: "GET" }).then(
            (res) => {
                if(!res.ok) {
                    throw new Error(res.status + " " + res.statusText);
                } else {
                    return res.json();
                }
            }
        ).then(
            (result) => {
                setPositions(result);
            },
            (error) => {
                console.log(error);
                alert("Something went wrong",
                    <Row>
                        <Col>
                            We're sorry. Something went wrong. Please try again later.
                        </Col>
                    </Row>,
                    () => {}
                );
            }
        );
    }, []);

    function addDays(date, days) {
        let dateCopy = new Date(date);
        dateCopy.setDate(dateCopy.getDate() + days);
        return dateCopy;
    }

    function buildFridays() {
        let tempDate = openingDate;
        
        return [...new Array(runningWeeks)].map((e, x) => {
            let i=x+1;
            let tempDateCopy = new Date(tempDate);
            tempDate = addDays(tempDate, 7);
            return (
                <Form.Check 
                    key={"availability-fr"+i} 
                    id={"availability-fr"+i} 
                    label={tempDateCopy.toLocaleDateString('en-us', { weekday:"short", month:"short", day:"numeric"}) } 
                    name={"fr"+i} 
                    defaultChecked
                    onChange={(e) => toggleUnavailable("fr"+i, e.target.checked)}
                />
            );
        });
    }

    function buildSaturdays() {
        let tempDate = addDays(openingDate, 1);
        
        return [...new Array(runningWeeks + extraSaturdays)].map((e, x) => {
            let i=x+1;
            let tempDateCopy = new Date(tempDate);
            tempDate = addDays(tempDate, 7);
            return (
                <Form.Check  
                    key={"availability-sa"+i} 
                    id={"availability-sa"+i} 
                    label={tempDateCopy.toLocaleDateString('en-us', { weekday:"short", month:"short", day:"numeric"}) } 
                    name={"sa"+i} 
                    defaultChecked
                    onChange={(e) => toggleUnavailable("sa"+i, e.target.checked)}
                />
            );
        });
    }

    function toggleLimitation(limitation, value) {
        if(limitations.includes(limitation) && !value) {
            let l = [...limitations];
            l.splice(l.indexOf(limitation), 1);
            setLimitations([...l]);
        } else if(!limitations.includes(limitation) && value) {
            setLimitations([...limitations, limitation]);
        }
    }

    function toggleUnavailable(day, value) {
        if(unavailable.includes(day) && value) {
            let u = [...unavailable];
            u.splice(u.indexOf(day), 1);
            setUnavailable([...u]);
        } else if(!unavailable.includes(day) && !value) {
            setUnavailable([...unavailable, day]);
        }
    }

    function handleSubmit(e) {
        const form = e.currentTarget;
        e.preventDefault();
        e.stopPropagation();
        
        setValidated(true);

        if (form.checkValidity() === true) {
            confirm("Confirm",
                <Row>
                    <Col>
                        Are you sure you wish to submit this application? You will not be able to edit it once submitted.
                    </Col>
                </Row>,
                () => {
                    let data = {
                        dateSubmited: new Date().toLocaleString(),
                        firstName, middleName, lastName, email, phone, street, city, state, zip, 
                        over16, returning, returningNotes, otherNotes, unavailable, limitations, position
                    };
                    
                    fetch('/api/applications/', { 
                        method: "POST" ,
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "rawData": JSON.stringify(data),
                            "festivalId": props.fest
                        })
                    }).then(
                        (res) => {
                            if(!res.ok) {
                                throw new Error(res.status + " " + res.statusText);
                            } else {
                                return true;
                            }
                        }
                    ).then(
                        (success) => { 
                            alert("Thank you",
                                <Row>
                                    <Col>
                                    Your application has been recieved. We will reach out to you in response soon.
                                    </Col>
                                </Row>,
                                () => { navigate('/'); }
                            );  
                        },
                        (error) => { console.log(error); }
                    );
                },
                () => {}
            );
        } else {
            alert("Invalid Input",
                <Row>
                    <Col>
                        Please fix the errors and submit again.
                    </Col>
                </Row>,
                () => {}
            ); 
        }
    }

    return (<>
        <Container fluid>
            <Row className="justify-content-center my-5">
                <Col xs={11} xl={9} xxl={7} className="rounded-3 border shadow-dark bg-white text-black">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="my-3">
                            <Col>
                                <h4>Fill out an application to join our team!</h4>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} lg={4}>
                                <Form.Label>Main Position of Interest:</Form.Label><br/>
                                <Form.Select required value={position} onChange={(e) => setPosition(e.target.value)}>
                                    <option value="">Select a Position...</option>
                                    {positions.map((p) => {
                                        return (
                                            <option key={"positionSelectOption" + p.id} value={p.id}>{p.name.toUpperCase()}</option>
                                        );
                                    })}
                                </Form.Select>
                            </Col>
                        </Row>

                        <Row className="my-5">
                            <Col xs={12} lg className="mb-2">
                                <FloatingLabel controlId="firstName" label="First Name">
                                    <Form.Control required type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} lg className="mb-2">
                                <FloatingLabel controlId="middleName" label="Middle Initial">
                                    <Form.Control type="text" placeholder="Middle Initial" value={middleName} onChange={(e) => setMiddleName(e.target.value)} />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} lg className="mb-2">
                                <FloatingLabel controlId="lastName" label="Last Name">
                                    <Form.Control required type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row className="my-5">
                            <Col xs={12} className="mb-2">
                                <FloatingLabel controlId="street" label="Street Address">
                                    <Form.Control required type="text" placeholder="Street Address" value={street} onChange={(e) => setStreet(e.target.value)} />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} lg className="mb-2">
                                <FloatingLabel controlId="city" label="City">
                                    <Form.Control required type="text" placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} lg className="mb-2">
                                <FloatingLabel controlId="state" label="State">
                                    <Form.Control required type="text" placeholder="State" value={state} onChange={(e) => setState(e.target.value)} />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} lg className="mb-2">
                                <FloatingLabel controlId="zip" label="Zip Code">
                                    <Form.Control required type="text" placeholder="Zip Code" value={zip} onChange={(e) => setZip(e.target.value)} />
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row className="my-5">
                            <Col xs={12} lg className="mb-2">
                                <FloatingLabel controlId="email" label="Email">
                                    <Form.Control required type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} lg className="mb-2">
                                <FloatingLabel controlId="phone" label="Phone">
                                    <Form.Control required type="tel" placeholder="Phone" pattern="[0-9]{3}-?[0-9]{3}-?[0-9]{4}" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row className="my-5">
                            <Col xs={12} className="mb-2">
                                <Form.Label>Seasonal Availability:</Form.Label><br/>
                                <Form.Text>
                                    We will need you to work 5pm to 1am (times will vary depending on crowds) Friday and Saturday night for {runningWeeks} weekends starting {openingDate.toLocaleDateString('en-us', { month:"long", day:"numeric"})}. {extraSaturdays > 0 && <>There will also be {extraSaturdays.toString()} additional night{extraSaturdays > 1 && <>s</>} at the end of the season for a lights out event.</>} Please check all days you will be available.
                                </Form.Text>
                            </Col>
                            <Col xs={6} lg={5} xxl={3} className="mb-2">
                                {buildFridays()}
                            </Col>
                            <Col xs={6} lg={5} xxl={3} className="mb-2">
                                {buildSaturdays()}
                            </Col>
                        </Row>

                        <Row className="my-5">
                            <Col xs={12} className="mb-2">
                                <Form.Label>Are you over the age of 16?</Form.Label><br/>
                                <Form.Check inline defaultChecked id="over16-yes" label="Yes" name="over16" type="radio" onChange={(e) => setOver16(e.target.checked)} />
                                <Form.Check inline id="over16-no" label="No" name="over16" type="radio" onChange={(e) => setOver16(!e.target.checked)} />
                            </Col>
                        </Row>

                        <Row className="my-5">
                            <Col xs={12} className="mb-2">
                                <Form.Label>Have you worked for us before?</Form.Label><br/>
                                <Form.Check inline id="returning-yes" label="Yes" name="returning" type="radio" onChange={(e) => setReturning(e.target.checked)} />
                                <Form.Check inline defaultChecked id="returning-no" label="No" name="returning" type="radio" onChange={(e) => setReturning(!e.target.checked)} />
                            </Col>
                            <Col xs={12} className="mb-2">
                                <Form.Label>If yes, what position/scene?</Form.Label><br/>
                                <FloatingLabel controlId="returning-explain" label="Explain here">
                                    <Form.Control as="textarea" placeholder="Explain here" style={{ height: '150px' }} value={returningNotes} onChange={(e) => setReturningNotes(e.target.value)} />
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row className="my-5">
                            <Col xs={12} className="mb-2">
                                <Form.Label><span className="fw-bold">[Actors Only]</span> Do you have any physical limitiations we should know about when placing you in a scene? Check all that apply.</Form.Label><br/>
                                <Form.Check id="limitations-allergies" label="Skin Allergies" name="limitations-allergies" onChange={(e) => toggleLimitation("allergies", e.target.checked)} />
                                <Form.Check id="limitations-breathing" label="Restricted Breathing Problems" name="limitations-breathing" onChange={(e) => toggleLimitation("breathing", e.target.checked)} />
                                <Form.Check id="limitations-light" label="Light Sensitivity" name="limitations-light" onChange={(e) => toggleLimitation("light", e.target.checked)} />
                                <Form.Check id="limitations-other" label="Other Physical Limitations" name="limitations-other" onChange={(e) => toggleLimitation("other", e.target.checked)} />
                            </Col>
                        </Row>

                        <Row className="my-5">
                            <Col xs={12} className="mb-2">
                                <Form.Label>Do you have any additional haunt work experience or any other comments/questions?</Form.Label><br/>
                                <FloatingLabel controlId="comments" label="Explain here">
                                    <Form.Control as="textarea" placeholder="Explain here" style={{ height: '150px' }} value={otherNotes} onChange={(e) => setOtherNotes(e.target.value)} />
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <Row className="my-5">
                            <Col xs={12} className="mb-2 text-center">
                                <Button type="submit" variant="dark" size="lg">Submit Application</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    </>)
}