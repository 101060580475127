import baseLogo from '../assets/logo.png';

import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, Link } from 'react-router-dom';
import {
    Navbar, Nav, NavDropdown,
    Container, Row, Col
} from 'react-bootstrap';
import { useAuth } from '../../_useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function EmployeeLayout() {
    return (<>
        <Helmet>
            <title>Fest Manager</title>
            <meta name="description" content="Festival Manager for the Renaissance Park Event Center Waynesville, Ohio" />
        </Helmet>
        <Header />
        <Outlet />
        <Footer />
    </>);
}

export function Header() {
    const { curUser, curFest, setCurFest } = useAuth();

    return (
        <Navbar id="employee-navbar" bg="dark" variant="dark" expand="lg">
            <Container fluid>
                <Navbar.Brand as={Link} to="/employee">
                    <img src={baseLogo} alt="Renaissance Park Festival Manager Logo" className="adminLogo" />
                    {curFest?.logo && <img src={curFest?.logo} alt="Festival Logo" className="adminLogo" />}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="employee-navbar-nav" />
                <Navbar.Collapse id="employee-navbar-nav">
                    <Nav className="ms-auto border-lg-right pe-md-3">
                        <Nav.Link as={Link} to="/employee/timeclock">TimeClock</Nav.Link>
                        {curFest?.enableTodos && <Nav.Link as={Link} to="/employee/todo">ToDo</Nav.Link>}
                        {curUser?.accessLvl >= 1 && <Nav.Link as={Link} to="/employee/admin">Admin</Nav.Link>}
                    </Nav>
                    <Nav className="ms-md-3">
                        {curUser ? <>
                            <NavDropdown title={curUser.username + " (" + curFest?.name + ")"} id="employee-navbar-nav-dropdown" align="end">
                                <NavDropdown.Item as={Link} to="#" onClick={() => {
                                    if(curUser.accessLvl >= 3) {
                                        setCurFest(null);
                                    }
                                }}>
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-right-arrow-left" size="sm" className="me-2" />
                                    Change Festival
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item as={Link} to="/employee/myaccount">
                                    <FontAwesomeIcon icon="fa-regular fa-user" size="sm" className="me-2" />
                                    My Account
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/logout">
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-right-from-bracket" size="sm" className="me-2" />
                                    Logout
                                </NavDropdown.Item>
                            </NavDropdown>
                        </>: <>
                            <Nav.Link as={Link} to="/login">
                                <FontAwesomeIcon icon="fa-solid fa-arrow-right-to-bracket" size="sm" className="me-2" />
                                Login
                            </Nav.Link>
                        </>}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export function Footer() {
    return (
        <footer id="employee-footer" className="pinned-footer border-top bg-dark text-white">
            <Container fluid>
                <Row className="justify-content-center">
                    <Col xs="auto">
                        &copy;{new Date().getFullYear()} Brimstone &amp; Fire LLC
                        {false && <>
                            <span> | </span>
                            <a href="mailto:management@brimstonehaunt.com" className="link-light">Management@BrimstoneHaunt.com</a>
                        </>}
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}