import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import useAuth from '../../_useAuth';
import {
    Container, Row, Col,
    Button, Form, FloatingLabel
} from 'react-bootstrap';

export default function Login() {
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();

    function handleSubmit(e) {
        e.preventDefault();
        login(e.target.username.value, e.target.password.value).then((success) => {
            if(success) {
                navigate(state?.path || "/employee");
            } else {
                alert("Unable to login!");
            }
        });
    }

    return (<>
        <Container fluid>
            <Row className="justify-content-center my-5">
                <Col xs="auto">
                    <h1>Please Login</h1>
                </Col>
            </Row>
            <Form onSubmit={handleSubmit}>
                <Row className="justify-content-center my-3">
                    <Col sm={8} md={6} xl={4}>
                        <FloatingLabel controlId="username" label="Username">
                            <Form.Control type="text" placeholder="Username" />
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="justify-content-center my-3">
                    <Col sm={8} md={6} xl={4}>
                        <FloatingLabel controlId="password" label="Password">
                            <Form.Control type="password" placeholder="Password" />
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="justify-content-center my-3">
                    <Col sm={4} md={3} xl={2}>
                        <div className="d-grid">
                            <Button size="lg" type="submit">Login</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Container>
    </>);
}