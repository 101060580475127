import * as React from 'react';
import {
    Row, Col, Form, FloatingLabel, Button
} from 'react-bootstrap';
import { useAlert } from '../../../_useAlert';
import useAuth from '../../../_useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Positions() {
    const { alert, confirm } = useAlert();
    const { curFest } = useAuth();
    const [editPositions, setEditPositions] = React.useState([]);
    const [events, setEvents] = React.useState([]);

    const refreshEventsPositions = React.useCallback(() => {
        fetch('/api/events?' + new URLSearchParams({"filter": JSON.stringify({"where": {"festivalId": curFest.id}})}), { method: "GET" }).then(
            (res) => {
                if(!res.ok) {
                    throw new Error(res.status + " " + res.statusText);
                } else {
                    return res.json();
                }
            }
        ).then(
            (result) => {
                setEvents(result);

                var eventIDs = result.map(event => event.id);
                fetch('/api/positions?' + new URLSearchParams({"filter": JSON.stringify({"where": {"eventId": {"inq": eventIDs}}})}), { method: "GET" }).then(
                    (res) => {
                        if(!res.ok) {
                            throw new Error(res.status + " " + res.statusText);
                        } else {
                            return res.json();
                        }
                    }
                ).then(
                    (result) => {
                        setEditPositions(result);
                    },
                    (error) => {
                        console.log(error);
                        alert("Something went wrong",
                            <Row>
                                <Col>
                                    Unable to load positions. Please refresh the page or try again later.
                                </Col>
                            </Row>,
                            () => {}
                        );
                    }
                );
            },
            (error) => {
                console.log(error);
                alert("Something went wrong",
                    <Row>
                        <Col>
                            Unable to load events. Please refresh the page or try again later.
                        </Col>
                    </Row>,
                    () => {}
                );
            }
        );
    }, [alert, curFest.id]);

    React.useEffect(() => {
        refreshEventsPositions();
    }, [refreshEventsPositions]);

    function handleInput(e) {
        var index = e.target.dataset.index;
        var key = e.target.getAttribute('name');
        var val = (key == "isTipped" || key == "canApply") ? e.target.checked : e.target.value;

        var temp = [...editPositions];
        temp[index] = {...editPositions[index], [key]: val};

        setEditPositions(temp);
    }

    function savePositions(e) {
        e.preventDefault();

        var promises = [];

        editPositions.forEach(function(position, index) {
            promises.push(
                fetch('/api/positions/' + position.id, { 
                    method: "PATCH" ,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "name": position.name,
                        "defaultPayRate": parseFloat(position.defaultPayRate),
                        "eventId": parseInt(position.eventId),
                        "canApply": position.canApply,
                        "isTipped": position.isTipped
                    })
                }).then(
                    (res) => {
                        if(!res.ok) {
                            throw new Error(res.status + " " + res.statusText);
                        } else {
                            return true;
                        }
                    }
                ).then(
                    (success) => {},
                    (error) => { console.log(error); }
                )
            );
        });

        Promise.all(promises).then(function() {
            alert("Success",
                <Row>
                    <Col>
                        Positions have been updated.
                    </Col>
                </Row>,
                () => { refreshEventsPositions(); }
            );
        }).catch(function () {
            alert("Something went wrong",
                <Row>
                    <Col>
                        Unable to update positions. Please refresh the page or try again later.
                    </Col>
                </Row>,
                () => {}
            );
        });
    }

    function addPosition() {
        confirm("New Position",
            <>
                <Row className="my-3" className="my-1">
                    <Col xs={12} lg>
                        <FloatingLabel controlId={"newPositionName"} label="Name">
                            <Form.Control type="text" name="name" placeholder="Name" />
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} lg className="my-1">
                        <FloatingLabel controlId={"newPositionPayrate"} label="Default Payrate">
                            <Form.Control type="number" step=".25" name="defaultPayRate" placeholder="Default Payrate" />
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} lg className="my-1">
                        <FloatingLabel controlId={"newPositionEvent"} label="Event">
                            <Form.Select aria-label="Event" name="eventId">
                                <option>Select One...</option>
                                {events.length && events.map(function(event) {
                                    return (
                                        <option key={event.id} value={event.id}>{event.name}</option>
                                    );
                                })}
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                </Row>
            </>,
            () => {
                var newPositionName = document.getElementById("newPositionName").value;
                var newPositionPayrate = document.getElementById("newPositionPayrate").value;
                var newPositionEvent = document.getElementById("newPositionEvent").value;

                if(newPositionName) {
                    fetch('/api/positions/', { 
                        method: "POST" ,
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "name": newPositionName,
                            "defaultPayRate": parseFloat(newPositionPayrate),
                            "eventId": parseInt(newPositionEvent)
                        })
                    }).then(
                        (res) => {
                            if(!res.ok) {
                                throw new Error(res.status + " " + res.statusText);
                            } else {
                                return true;
                            }
                        }
                    ).then(
                        (success) => {refreshEventsPositions();},
                        (error) => { console.log(error); }
                    );
                } else {
                    alert("Something went wrong",
                        <Row>
                            <Col>
                                Unable to create new position. Please refresh the page or try again later.
                            </Col>
                        </Row>,
                        () => {}
                    );
                }
            },
            () => {}
        );
    }

    return (<>
        <Row>
            <Col xs={12}>
                <Row className="my-3">
                    <Col>
                        <h1>Positions</h1>
                    </Col>
                    <Col xs="auto">
                        <Button variant="link" className="link-success" onClick={addPosition}>
                            <FontAwesomeIcon icon="fa-regular fa-square-plus" size="2x" />
                        </Button>
                    </Col>
                </Row>
                <Form onSubmit={savePositions}>
                    {editPositions.length && editPositions.map(function(position, index) {
                        return (
                            <Row key={index} className="my-4">
                                <Col xs={2} md={1} className="my-1">
                                    <FloatingLabel controlId={position.id + "ID"} label="ID">
                                        <Form.Control type="text" placeholder="ID"value={position.id} disabled />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={10} md={11} lg className="my-1">
                                    <FloatingLabel controlId={position.id + "name"} label="Name">
                                        <Form.Control required type="text" name="name" placeholder="Name" data-index={index} value={position.name} onChange={handleInput} />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={6} lg className="my-1">
                                    <FloatingLabel controlId={position.id + "payrate"} label="Default Payrate">
                                        <Form.Control required type="number" step=".25" name="defaultPayRate" placeholder="Default Payrate" data-index={index} value={position.defaultPayRate} onChange={handleInput} />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={6} lg className="my-1">
                                    <FloatingLabel controlId={position.id + "event"} label="Event">
                                        <Form.Select aria-label="Event" name="eventId" data-index={index} defaultValue={position.eventId} onChange={handleInput}>
                                            {events.length && events.map(function(event) {
                                                return (
                                                    <option key={event.id} value={event.id}>{event.name}</option>
                                                );
                                            })}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col xs={12} lg="auto" className="text-end my-1">
                                    <Form.Label className="d-block">
                                        Position is Tipped 
                                        <Form.Switch className="d-inline-block" name="isTipped" data-index={index} checked={position.isTipped} onChange={handleInput} />
                                    </Form.Label>
                                    <Form.Label className="d-block">
                                        Show on Application 
                                        <Form.Switch className="d-inline-block" name="canApply" data-index={index} checked={position.canApply} onChange={handleInput} />
                                    </Form.Label>
                                </Col>
                            </Row>
                        );
                    })}
                    <Row className="justify-content-center my-3">
                        <Col sm={6} md={5} xl={4}>
                            <div className="d-grid">
                                <Button size="lg" type="submit">Save</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    </>);
}