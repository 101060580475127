import * as React from 'react';
import { Navigate } from 'react-router-dom';
import usePublic from '../_usePublic';

import Brimstone from './brimstonehaunt.com'

export default function Apply() {
    const { loadConfig } = usePublic();
    const [confReady, setConfReady] = React.useState(false);
    const [conf, setConf] = React.useState([]);
    const [content, setContent] = React.useState(null);

    let selectContent = (host, fest) => {
        if(host === "brimstonehaunt") {
            setContent(<Brimstone.Apply fest={fest} />);
        } else {
            setContent(<Navigate to="/" replace />);
        }
    }

    React.useEffect(function() {
        loadConfig((result) => {
            setConf(result);
            setConfReady(true);
        });
    }, []);

    React.useEffect(function() {
        if(!confReady) return;

        let host = window.location.hostname;
        let chunks = host.split(".");
        let tempHost = "";
        let tempFest = -1;
        
        conf.forEach((e) => {
            if((!chunks.includes("fm") && !chunks.includes("admin")) && chunks.includes(e.hostName) && e.enableApps) {
            // if(chunks.includes(e.hostName) && e.enableApps) {
                tempHost = e.hostName;
                tempFest = e.id;
            }
        });
        selectContent(tempHost, tempFest);
    }, [confReady]);

    return (<>{(confReady && content !== null) ? content : <>LOADING........</>}</>);
}