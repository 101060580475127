import * as React from 'react';
import { QRCode } from 'react-qrcode-logo';
import { DefaultUserPhoto } from '../../../_defaultUserPhoto';

export default function Badge(props) {
    return (<>
        <div className="text-center border rounded" style={{"width": "486px", "height": "306px", "overflow": "hidden"}}>
            <div className="d-flex justify-content-evenly my-3">
                <div style={{"border-radius": "10px", "border": "1px solid #4d4d4d", "overflow": "hidden"}}>
                    <div style={{"font-size": "30px", "line-height": "30px", "color": "#a400fd", "font-weight": "bold", "background-color": "#ffffff"}}>
                        {new Date().getFullYear()}
                    </div>
                    <img src={props.user.photo || DefaultUserPhoto} alt="employee" height={175} width={200} />
                </div>
                <div>
                    <QRCode
                        value={"309A2F5E8117B212" + props.user.id + "E0914F41D177AEBA"} 
                        size={200} 
                        quietZone={0}
                        // logoImage="/media/img/renfest_logo.png"
                        ecLevel="H"
                        qrStyle="dots" 
                        eyeRadius={12}
                        logoWidth={150}
                        logoOpacity={0.5}
                        // removeQrCodeBehindLogo
                    />
                </div>
            </div>
            <div style={{"color": "#ffffff", "backgroundColor": "#a400fd", "paddingTop": "3px", "paddingBottom": "3px"}}>
                <div className="fw-bold fs-4">{props.user.firstName} {props.user.middleName} {props.user.lastName}</div>
                <div>
                    {props.user.positions?.map(function(pos, index) {
                        return (<React.Fragment key={index}>
                            {index !== 0 && " | "}
                            {pos.event.name + " - " + pos.name}
                        </React.Fragment>);
                    }) || "- - - - - - - - - - - - - - - - - - - - - - - - -"}
                </div>
            </div>
        </div>
    </>);
}