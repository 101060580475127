import * as React from 'react';
import {
    Container, Row, Col, Button
} from 'react-bootstrap';
import Badge from './badge';
import ReactToPrint, { PrintContextConsumer } from "react-to-print";

export default function PrintBadges(props) {
    const ref = React.useRef();

    return (<Container>
        <Row className="justify-content-center mb-5">
            <Col xs={12} xl={6}>
                <div className="d-grid">
                    <ReactToPrint content={() => ref.current}>
                        <PrintContextConsumer>
                            {({ handlePrint }) => (
                                <Button variant="primary" onClick={handlePrint}>Print</Button>
                            )}
                        </PrintContextConsumer>
                    </ReactToPrint>
                </div>
            </Col>
        </Row>
        <Row className="justify-content-center">
            <Col xs="auto">
                <div ref={ref}>
                    {props.users.length && props.users.map(function(user, index) {
                        return (
                            <div key={user.id} style={index !== props.users.length - 1 ? {"breakAfter": "page", "pageBreakAfter": "always"} : {}}>
                                <Badge user={user} />
                            </div>
                        );
                    })}
                </div>
            </Col>
        </Row>
    </Container>);
}