import baseLogo from '../assets/logo.png';
import './style.scss';

import * as React from 'react';
import {
    Container, Row, Col, Button, Form, FloatingLabel
} from 'react-bootstrap';
import { QrReader } from 'react-qr-reader';
import { useAlert } from '../../_useAlert';

export default function Scanner() {
    const { alert, confirm } = useAlert();
    const [data, setData] = React.useState('Scanning...');
    const [isPaused, setIsPaused] = React.useState(false);
    const [vfClass, setVfClass] = React.useState("");
    const [showScanner, setShowScanner] = React.useState(false);

    function handleStartScanning() {
        setShowScanner(true);
        setTimeout(function () {
            setShowScanner(false);
        }, 300000); // 5 mins
    }

    function handleScan (qrData) {
        var isPausedFromParent = document.getElementById('qrReaderParent').dataset.ispaused;
        if ((!isPausedFromParent || isPausedFromParent === "false") && qrData) {
            setIsPaused(true);
            setData("Waiting for Server...");
            handleStatus(0);
            // post to clockin
            fetch('/api/badgescan', { 
                method: "POST" ,
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({badgeCode: qrData.text})
            }).then(
                (res) => {if(!res.ok) {throw new Error(res.status + " " + res.statusText);} else {return res.json();}}
            ).then(
                (result) => {
                    if(result.upRecords) { // handle multi position response
                        confirm("Make a selection",
                            <>
                                <Row className="mb-3">
                                    <Col>
                                        Which position are you clocking in for?
                                    </Col>
                                </Row>
                                {result.upRecords.map(function(position, index) {
                                    var pos = result.positions?.find(p => p.id === position.positionId);
                                    return (<Form.Check 
                                        key={index} 
                                        id={"userPosition-" + position.id} 
                                        type="radio" 
                                        label={pos.event?.name + " - " + pos.name}
                                        name="userposition" 
                                        value={position.id} 
                                        defaultChecked={index === 0} 
                                    />);
                                })}
                            </>,
                            () => {
                                // post to badgescan
                                fetch('/api/badgescan', { 
                                    method: "POST" ,
                                    headers: {'Content-Type': 'application/json'},
                                    body: JSON.stringify({badgeCode: qrData.text, upId: document.querySelector('input[name="userposition"]:checked').value})}
                                ).then(
                                    (res) => {if(!res.ok) {throw new Error(res.status + " " + res.statusText);} else {return res.json();}}
                                ).then(
                                    (result) => {
                                        if(result.success) {
                                            result.clockedIn && setData("Clocked In");
                                            result.clockedOut && setData("Clocked Out");
                                            handleStatus(1);
                                        } else {
                                            setData("ERROR");
                                            handleStatus(-1);
                                        }
                                    },
                                    (error) => {
                                        console.log(error);
                                        alert("Something went wrong",
                                            <Row>
                                                <Col>
                                                    Unable to clock in/out. Please refresh the page or try again later.
                                                </Col>
                                            </Row>,
                                            () => {setData("ERROR"); handleStatus(-1);}
                                        );
                                    }
                                );
                            },
                            () => {setData("Cancelled"); handleStatus(-1);}
                        );
                    } else if(result.wasTipped) { // handle tipped position clockout response
                        confirm(result.timesheetUser,
                            <>
                                <Row className="mb-3">
                                    <Col>
                                        Please enter your tips:
                                    </Col>
                                </Row>
                                <Row className="my-3">
                                    <Col>
                                        <FloatingLabel controlId="scannerCashTipsInput" label="Cash Tips">
                                            <Form.Control type="number" step="0.25" name="cashTips" placeholder="Cash Tips" />
                                        </FloatingLabel>
                                    </Col>   
                                    <Col>
                                        <FloatingLabel controlId="scannerCreditTipsInput" label="Credit Tips">
                                            <Form.Control type="number" step="0.25" name="creditTips" placeholder="Credit Tips" />
                                        </FloatingLabel>
                                    </Col>        
                                </Row>
                            </>,
                            () => {
                                var cashTips = parseFloat(document.getElementById('scannerCashTipsInput').value);
                                var creditTips = parseFloat(document.getElementById('scannerCreditTipsInput').value);
                                // post to badgescan with tips data
                                fetch('/api/badgescan', { 
                                    method: "POST" ,
                                    headers: {'Content-Type': 'application/json'},
                                    body: JSON.stringify({badgeCode: qrData.text, timesheetId: result.timesheetId, cashTips: cashTips, creditTips: creditTips})}
                                ).then(
                                    (res) => {if(!res.ok) {throw new Error(res.status + " " + res.statusText);} else {return res.json();}}
                                ).then(
                                    (result) => {
                                        if(result.success) {
                                            result.clockedIn && setData("Clocked In");
                                            result.clockedOut && setData("Clocked Out");
                                            handleStatus(1);
                                        } else {
                                            setData("ERROR");
                                            handleStatus(-1);
                                        }
                                    },
                                    (error) => {
                                        console.log(error);
                                        alert("Something went wrong",
                                            <Row>
                                                <Col>
                                                    Unable to clock in/out. Please refresh the page or try again later.
                                                </Col>
                                            </Row>,
                                            () => {setData("ERROR"); handleStatus(-1);}
                                        );
                                    }
                                );
                            },
                            () => {setData("Cancelled"); handleStatus(-1);}
                        );
                    } else {
                        if(result.success) {
                            result.clockedIn && setData("Clocked In");
                            result.clockedOut && setData("Clocked Out");
                            handleStatus(1);
                        } else {
                            setData("ERROR");
                            handleStatus(-1);
                        }
                    }
                },
                (error) => {
                    console.log(error);
                    alert("Something went wrong",
                        <Row>
                            <Col>
                                Unable to clock in/out. Please refresh the page or try again later.
                            </Col>
                        </Row>,
                        () => {setData("ERROR"); handleStatus(-1);}
                    );
                }
            );
        }
    }

    function handleStatus(s) {
        if (s === -1) {
            setVfClass('error');
        } else if (s === 0) {
            setVfClass('waiting');
        } else if (s === 1) {
            setVfClass('success');
        }

        if(s !== 0) {
            setTimeout(function () {
                setVfClass("");
                setIsPaused(false);
                setData('Scanning...');
            }, 3000);
        }
    }

    return (<>
        <Container fluid id="scannerContainer" className="py-5 bg-dark text-white">
            <Row className="justify-content-center mb-5">
                <Col xs="auto">
                    <img src={baseLogo} alt="Renaissance Park Festival Manager Logo" height={50} />
                </Col>
            </Row>
            {showScanner ? <>
                <Row className="justify-content-center">
                    <Col xs="auto">
                        <h1>{data}</h1>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col id="qrReaderParent" xs={11} sm={10} md={9} lg={6} xl={4} xxl={3} className="p-0" data-vfclass={vfClass} data-ispaused={isPaused}>
                        <QrReader constraints={{facingMode: 'user'}} onResult={handleScan} ViewFinder={ () => { return (<div id="viewFinder" className={vfClass}><div></div></div>) }} />
                    </Col>
                </Row>
            </> : <>
                <Row className='justify-content-center'>
                    <Col xs="auto">
                        <Button variant="outline-light" size="lg" onClick={handleStartScanning}>Start Scanning</Button>
                    </Col>
                </Row>
            </>}
        </Container>
    </>);
}