import * as React from 'react';
import {
    Container, Row, Col, Form, FloatingLabel, Button, Dropdown, Modal
} from 'react-bootstrap';
import { useAlert } from '../../../_useAlert';
import useAuth from '../../../_useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Applications() {
    const { alert, confirm } = useAlert();
    const { curUser, curFest } = useAuth();

    const [openingDate, setOpeningDate] = React.useState(new Date("Sep 13, 2024 19:00:00"));
    const [runningWeeks, setRunningWeeks] = React.useState(7);
    const [extraSaturdays, setExtraSaturdays] = React.useState(1);
    const [fridays, setFridays] = React.useState({});
    const [saturdays, setSaturdays] = React.useState({});

    const [showHire, setShowHire] = React.useState(false);
    const [editUser, setEditUser] = React.useState(null);

    const [allApps, setAllApps] = React.useState([]);
    const [apps, setApps] = React.useState([]);
    const [filteredApps, setFilteredApps] = React.useState([]);
    const [events, setEvents] = React.useState([]);
    const [positionMap, setPositionMap] = React.useState({});
    const [filter, setFilter] = React.useState("");
    const [statusFilter, setStatusFilter] = React.useState("pending");
    const [eventFilter, setEventFilter] = React.useState("");
    const [positionFilter, setPositionFilter] = React.useState("");
    const [availabilityFilter, setAvailabilityFilter] = React.useState("");
    const [limitationFilter, setLimitationFilter] = React.useState("");
    const [ageFilter, setAgeFilter] = React.useState("");
    const [returningFilter, setReturningFilter] = React.useState("");

    function addDays(date, days) {
        let dateCopy = new Date(date);
        dateCopy.setDate(dateCopy.getDate() + days);
        return dateCopy;
    }

    function buildFridays() {
        let tempFridays = {};
        let tempDate = openingDate;
        
        for(var x = 0; x < runningWeeks; x++) {
            let i=x+1;
            let tempDateCopy = new Date(tempDate);
            tempDate = addDays(tempDate, 7);
            tempFridays = {...tempFridays, ["fr"+i]: tempDateCopy.toLocaleDateString('en-us', { weekday:"short", month:"short", day:"numeric"})};
        };

        setFridays(tempFridays);
    }

    function buildSaturdays() {
        let tempSaturdays = {}
        let tempDate = addDays(openingDate, 1);
        
        for(var x = 0; x < runningWeeks + extraSaturdays; x++) {
            let i=x+1;
            let tempDateCopy = new Date(tempDate);
            tempDate = addDays(tempDate, 7);
            tempSaturdays = {...tempSaturdays, ["sa"+i]: tempDateCopy.toLocaleDateString('en-us', { weekday:"short", month:"short", day:"numeric"})};
        };

        setSaturdays(tempSaturdays);
    }

    const refreshEventsPositions = React.useCallback(function() {
        fetch('/api/events?' + new URLSearchParams({"filter": JSON.stringify({"include":["positions"]})}), { method: "GET" })
        .then((res) => {if(!res.ok) {throw new Error(res.status + " " + res.statusText);} else {return res.json();}})
        .then((result) => {
            setEvents(result);
            if(Array.isArray(result) && result.length > 0) {
                let temp = {};
                result.forEach(event => {
                    event?.positions?.forEach((pos) => {
                        temp[pos.id] = { name: pos.name, defaultPayRate: pos.defaultPayRate };
                    });
                });
                setPositionMap(temp);
            }
        },(error) => {console.log(error);});
    }, []);

    const refreshApps = React.useCallback(() => {
        fetch('/api/applications?' + new URLSearchParams({"filter": JSON.stringify({"where": {"festivalId": curFest.id}})}), { method: "GET" }).then(
            (res) => {
                if(!res.ok) {
                    throw new Error(res.status + " " + res.statusText);
                } else {
                    return res.json();
                }
            }
        ).then(
            (result) => {
                if(Array.isArray(result) && result.length > 0) {
                    let tempApps = [];
                    result.forEach(app => {
                        let data = JSON.parse(app.rawData);

                        tempApps.push({
                            name: (data.firstName + " " + data.middleName + " " + data.lastName),
                            positionId: parseInt(data.position),
                            over16: data.over16,
                            returning: data.returning,
                            limitations: data.limitations?.length || 0,
                            unavailable: data.unavailable?.length || 0,
                            data: data,
                            ...app
                        });
                    });

                    setApps(tempApps);
                    setAllApps(tempApps);
                } else {
                    setApps([]);
                    setAllApps([]);
                }
            },
            (error) => {
                console.log(error);
                alert("Something went wrong",
                    <Row>
                        <Col>
                            Unable to load applications. Please refresh the page or try again later.
                        </Col>
                    </Row>,
                    () => {}
                );
            }
        );
    }, [alert, curFest.id]);

    React.useEffect(() => {
        buildFridays();
        buildSaturdays();

        refreshEventsPositions();
        refreshApps();
    }, [refreshEventsPositions, refreshApps]);


    function updateStatusFilter(e) {
        setStatusFilter(e.target.value);
    }

    function updateEventFilter(e) {
        setEventFilter(e.target.value);
    }

    function updatePositionFilter(e) {
        setPositionFilter(e.target.value);
    }

    function updateAvailableFilter(e) {
        setAvailabilityFilter(e.target.value);
    }

    function updateLimitationFilter(e) {
        setLimitationFilter(e.target.value);
    }

    function updateAgeFilter(e) {
        setAgeFilter(e.target.value);
    }

    function updateReturningFilter(e) {
        setReturningFilter(e.target.value);
    }

    const updateFilter = React.useCallback(function(e) {
        var f = filter;
        if(e) {
            setFilter(e.target.value);
            f = e.target.value;
        }

        setFilteredApps(apps.filter(function(app) {
            return app.rawData?.toLowerCase()?.indexOf(f.toLowerCase()) !== -1;
        }));
    }, [apps, filter]);

    React.useEffect(() => {
        updateFilter();
    }, [updateFilter, apps]);

    React.useEffect(() => {
        var filteredApps = [];

        if(statusFilter === "pending") {
            filteredApps = allApps.filter(function(app) {
                return app.status === "new" || app.status === "held";
            });
        } else {
            filteredApps = allApps.filter(function(app) {
                return app.status === statusFilter;
            });
        }

        // if(eventFilter) {
        //     filteredUsers = filteredUsers.filter(function(user) {
        //         var found = false;
        //         user.positions?.forEach(function(pos) {
        //             if(pos.eventId === parseInt(eventFilter)) {
        //                 found = true;
        //             }
        //         });
        //         return found;
        //     });
        // }
        
        if(positionFilter) {
            filteredApps = filteredApps.filter(function(app) {
                return app.positionId === parseInt(positionFilter)
            });
        }

        if(availabilityFilter) {
            filteredApps = filteredApps.filter(function(app) {
                if (availabilityFilter === "full") { return app.unavailable === 0; }
                else if (availabilityFilter === "good") { return app.unavailable > 0 && app.unavailable < 5; }
                else if (availabilityFilter === "some") { return app.unavailable > 4; }
            });
        }

        if(limitationFilter) {
            filteredApps = filteredApps.filter(function(app) {
                if (limitationFilter === "none") { return app.limitations === 0; }
                else if (limitationFilter === "some") { return app.limitations > 0; }
            });
        }

        if(ageFilter) {
            filteredApps = filteredApps.filter(function(app) {
                return app.over16 === (ageFilter === 'true');
            });
        }

        if(returningFilter) {
            filteredApps = filteredApps.filter(function(app) {
                return app.returning === (returningFilter === 'true');
            });
        }

        setApps(filteredApps);
    }, [statusFilter, eventFilter, positionFilter, availabilityFilter, limitationFilter, ageFilter, returningFilter, allApps]);

    function mapAvailability(arr) {
        return arr.map((a) => {
            return (fridays[a] || saturdays[a] || "UNKNOWN");
        });
    }

    function handleMove(appId, rawData, positionId) {
        let temp = JSON.parse(rawData);
        temp.position = positionId;

        fetch('/api/applications/' + appId, { 
            method: "PATCH" ,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "rawData": JSON.stringify(temp)
            })
        }).then(
            (res) => {
                if(!res.ok) {
                    throw new Error(res.status + " " + res.statusText);
                } else {
                    return true;
                }
            }
        ).then(
            (success) => {refreshApps()},
            (error) => { console.log(error); }
        );
    }

    function handleHold(appId) {
        fetch('/api/applications/' + appId, { 
            method: "PATCH" ,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "status": "held"
            })
        }).then(
            (res) => {
                if(!res.ok) {
                    throw new Error(res.status + " " + res.statusText);
                } else {
                    return true;
                }
            }
        ).then(
            (success) => {refreshApps()},
            (error) => { console.log(error); }
        );
    }

    function handleReject(appId) {
        fetch('/api/applications/' + appId, { 
            method: "PATCH" ,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "status": "rejected"
            })
        }).then(
            (res) => {
                if(!res.ok) {
                    throw new Error(res.status + " " + res.statusText);
                } else {
                    return true;
                }
            }
        ).then(
            (success) => {refreshApps()},
            (error) => { console.log(error); }
        );
    }

    function handleHire(app) {
        let data = JSON.parse(app.rawData);

        let user = {
            appId: app.id,
            firstName: data.firstName,
            middleName: data.middleName,
            lastName: data.lastName,
            email: data.email,
            phone: data.phone,
            paychexId: "",
            username: "",
            positionId: app.positionId,
            payrate: positionMap[app.positionId]?.defaultPayRate
        };

        setEditUser(user);
        setShowHire(true);
    }

    function closeHireModal() {
        setEditUser(null);
        setShowHire(false);
    }

    function hireApp(e) {
        e.preventDefault();

        if(!editUser) {
            alert("Something went wrong",
                <Row>
                    <Col>
                        Please refresh the page or try again later.
                    </Col>
                </Row>,
                () => {}
            );
            return;
        }

        fetch('/api/users/signup', { 
            method: "POST" ,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "firstName": editUser.firstName?.trim(),
                "middleName": editUser.middleName?.trim() || "",
                "lastName": editUser.lastName?.trim(),
                "email": editUser.email?.trim() || "",
                "phone": editUser.phone?.trim() || "",
                "paychexId": editUser.paychexId?.trim() || "000",
                "accessLvl": parseInt(editUser.accessLvl) || 0,
                "username": editUser.username?.trim(),
                "festivalId": curFest?.id
            })
        }).then(
            (res) => {
                if(!res.ok) {
                    if(res.status === 403) {
                        return res.json();
                    } else {
                        throw new Error(res.status + " " + res.statusText);
                    }
                } else {
                    return res.json();
                }
            }
        ).then(
            (result) => {
                if(result.error) {
                    alert("Something went wrong",
                        <Row>
                            <Col>
                                {result.error.message}
                            </Col>
                        </Row>,
                        () => {}
                    );
                } else {
                    let newUserId = result.id;
                    // update user to add position
                    fetch('/api/users/' + newUserId + '/addposition', { 
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "positionId": parseInt(editUser?.positionId),
                            "payrate": parseFloat(editUser?.payrate)
                        })
                    }).then(
                        (res) => {
                            if(!res.ok) {
                                throw new Error(res.status + " " + res.statusText);
                            } else {
                                return true;
                            }
                        }
                    ).then(
                        (success) => {
                            fetch('/api/applications/' + editUser?.appId, { 
                                method: "PATCH" ,
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "userId": newUserId,
                                    "status": "hired"
                                })
                            }).then(
                                (res) => {
                                    if(!res.ok) {
                                        throw new Error(res.status + " " + res.statusText);
                                    } else {
                                        return true;
                                    }
                                }
                            ).then(
                                (success) => {refreshApps(); closeHireModal();},
                                (error) => { console.log(error); }
                            );
                        },
                        (error) => {
                            console.log(error);
                            alert("Something went wrong",
                                <Row>
                                    <Col>
                                        Applicant was hired but we were unable to add their position. Please assign them a position manually.
                                    </Col>
                                </Row>,
                                () => {}
                            );
                        }
                    );
                }
            },
            (error) => {
                console.log(error);
                alert("Something went wrong",
                    <Row>
                        <Col>
                            Please refresh the page or try again later.
                        </Col>
                    </Row>,
                    () => {}
                );
            }
        );
    }

    function handleInput(e) {
        var key = e.target.getAttribute('name');
        var val = e.target.value;

        setEditUser({
            ...editUser,
            [key]: val
        });
    }

    function updateNotes(e, appId) {
        const { value } = e.target;
        setAllApps((app) =>
            app?.map( (a) => a.id === appId ? { ...a, notes: value, notesChanged: true } : a )
        );
    }

    function saveNotes(appId, notes) {
        fetch('/api/applications/' + appId, { 
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "notes": notes
            })
        }).then(
            (res) => {
                if(!res.ok) {
                    throw new Error(res.status + " " + res.statusText);
                } else {
                    return true;
                }
            }
        ).then(
            (success) => {
                let tempApps = allApps.map((app) => {
                    if(app.id === appId) {
                        return { ...app, notesChanged: false }
                    } else {
                        return app;
                    }
                });
        
                setAllApps(tempApps);
            },
            (error) => { console.log(error); }
        );
    }

    return (<>
        <Row className="my-3">
            <Col>
                <h1>Applications</h1>
            </Col>
        </Row>
        <Row className='justify-content-center my-3'>
            <Col>
                <FloatingLabel controlId="userStatusFilter" label="Status">
                    <Form.Select aria-label="Applications Status Filter" defaultValue="pending" onChange={updateStatusFilter}>
                        <option value="pending">Pending</option>
                        <option value="new">New</option>
                        <option value="held">Held</option>
                        <option value="rejected">Rejected</option>
                    </Form.Select>
                </FloatingLabel>
            </Col>
            {/* <Col>
                <FloatingLabel controlId="userEventFilter" label="Event">
                    <Form.Select aria-label="Event Filter" onChange={updateEventFilter}>
                        <option value="">All Events</option>
                        {events.length && events.map(function(event) {
                            return (
                                <option key={event.id} value={event.id}>{event.name}</option>
                            );
                        })}
                    </Form.Select>
                </FloatingLabel>
            </Col> */}
            <Col>
                <FloatingLabel controlId="userPositionFilter" label="Position">
                    <Form.Select aria-label="Position Filter" onChange={updatePositionFilter}>
                        <option value="">All Positions</option>
                        {events.length && events.map(function(event) {
                            if(!eventFilter || eventFilter === "" || eventFilter === event.id.toString()) {
                                return (
                                    <optgroup key={event.id} label={event.name}>
                                        {event.positions?.length && event.positions.map(function(position) {
                                            return (
                                                <option key={position.id} value={position.id}>{position.name}</option>
                                            );
                                        })}
                                    </optgroup>
                                );
                            } else {
                                return(<React.Fragment key={event.id}></React.Fragment>);
                            }
                        })}
                    </Form.Select>
                </FloatingLabel>
            </Col>
            <Col>
                <FloatingLabel controlId="appAvailabilityFilter" label="Availability">
                    <Form.Select aria-label="Availability Filter" onChange={updateAvailableFilter}>
                        <option value="">All Availability</option>
                        <option value="full">Full Availability</option>
                        <option value="good">Good Availability</option>
                        <option value="some">Some Availability</option>
                    </Form.Select>
                </FloatingLabel>
            </Col>
            <Col xs={12} xl={4} className="mt-3 mt-xl-0"></Col>
        </Row>
        <Row className='justify-content-center my-3'>
            <Col>
                <FloatingLabel controlId="appLimitationFilter" label="Limitations">
                    <Form.Select aria-label="Limitation Filter" onChange={updateLimitationFilter}>
                        <option value="">All Limitations</option>
                        <option value="none">No Limitations</option>
                        <option value="some">Some Limitations</option>
                    </Form.Select>
                </FloatingLabel>
            </Col>
            <Col>
                <FloatingLabel controlId="appAgeFilter" label="Age">
                    <Form.Select aria-label="Age Filter" onChange={updateAgeFilter}>
                        <option value="">All Ages</option>
                        <option value="true">Over 16</option>
                        <option value="false">16 and Under</option>
                    </Form.Select>
                </FloatingLabel>
            </Col>
            <Col>
                <FloatingLabel controlId="appReturningFilter" label="Returning">
                    <Form.Select aria-label="Returning Filter" onChange={updateReturningFilter}>
                        <option value="">All</option>
                        <option value="true">Returning Employee</option>
                        <option value="false">New Employee</option>
                    </Form.Select>
                </FloatingLabel>
            </Col>
            <Col xs={12} xl={4} className="mt-3 mt-xl-0">
                <FloatingLabel controlId="usersFilter" label="Filter">
                    <Form.Control type="text" name="filter" placeholder="Filter" value={filter} onChange={updateFilter} />
                </FloatingLabel>
            </Col>
        </Row>
        <Row className="divider dotted"></Row>
        <Row>
            {filteredApps.map((a, i) => {
                return (
                    <Col xxl={4} lg={6} xs={12} key={"app" + i} className="my-3">
                        <div className={`cell p-2 ${a.status === 'held' && 'bg-warning bg-opacity-10'} ${a.status === 'rejected' && 'bg-danger bg-opacity-10'}`}>
                            <Row className="mt-1 mb-3">
                                <Col className="align-self-center">
                                    <h3>{a.name} <span className="fs-6 align-middle">({positionMap[a.positionId]?.name?.toUpperCase()})</span></h3>
                                </Col>
                                <Col xs="auto" className="align-self-top">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="link" className="no-arrow">
                                            <FontAwesomeIcon icon="fa-solid fa-ellipsis" size="xl" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu renderOnMount={true}>
                                            <Dropdown.Item onClick={() => handleHire(a)}>Hire</Dropdown.Item>
                                            {a.status !== 'held' && <Dropdown.Item onClick={() => handleHold(a.id)}>Hold</Dropdown.Item>}
                                            {a.status !== 'rejected' && <Dropdown.Item onClick={() => handleReject(a.id)}>Reject</Dropdown.Item>}
                                            <div className="dropdown-submenu-toggle">
                                                <Dropdown.Item onClick={(e) => { 
                                                    e.preventDefault(); 
                                                    e.stopPropagation(); 
                                                    return false; 
                                                }}>
                                                    <FontAwesomeIcon icon="fa-solid fa-angle-left" size="xs" /> Move to
                                                </Dropdown.Item>
                                                <Dropdown.Menu renderOnMount={true} className="dropdown-submenu dropdown-submenu-left">
                                                    {events.length && events.map(function(event) {
                                                        return event.positions?.length && event.positions.map(function(position) {
                                                            return (
                                                                <Dropdown.Item key={position.id} onClick={() => handleMove(a.id, a.rawData, position.id)}>
                                                                    {event.name} - {position.name}
                                                                </Dropdown.Item>
                                                            );
                                                        });
                                                    })}
                                                </Dropdown.Menu>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                            <div className="my-3">
                                {!a.over16 && <Row className="bg-danger bg-opacity-25 my-1 fw-bold py-2">
                                    <Col>
                                        16 or Under
                                    </Col>
                                </Row>}
                                {a.unavailable === 0 && <Row className="bg-success bg-opacity-25 my-1 fw-bold py-2">
                                    <Col>
                                        Full Availability
                                    </Col>
                                </Row>}
                                {a.unavailable > 0 && a.unavailable < 5 && <Row className="bg-warning bg-opacity-25 my-1 fw-bold py-2">
                                    <Col>
                                        Good Availability
                                    </Col>
                                </Row>}
                                {a.unavailable > 4 && <Row className="bg-danger bg-opacity-25 my-1 fw-bold py-2">
                                    <Col>
                                        Some Availability
                                    </Col>
                                </Row>}
                                {a.limitations === 0 && <Row className="bg-success bg-opacity-25 my-1 fw-bold py-2">
                                    <Col>
                                        No Limitiations
                                    </Col>
                                </Row>}
                                {a.limitations > 0 && <Row className="bg-warning bg-opacity-25 my-1 fw-bold py-2">
                                    <Col>
                                        Some Limitiations
                                    </Col>
                                </Row>}
                            </div>
                            <Row className="my-3">
                                <Col>
                                    <Row>
                                        <Col>
                                            <Form.Label>Management Notes:</Form.Label>
                                        </Col>
                                        {a.notesChanged &&
                                            <Col xs="auto">
                                                <Button variant="link" className="text-danger" onClick={() => saveNotes(a.id, a.notes)}>
                                                    <FontAwesomeIcon icon="fa-regular fa-floppy-disk" size="xl" />
                                                </Button>
                                            </Col>
                                        }
                                    </Row>
                                    <Form.Control 
                                        as="textarea" 
                                        placeholder="Notes" 
                                        style={{ height: '100px' }} 
                                        key={a.id}
                                        defaultValue={a.notes || ""}
                                        onChange={(e) => updateNotes(e, a.id) } />
                                </Col>
                            </Row>
                            <div className="mh-lg-350px">
                                {Object.keys(a.data).map((key) => {
                                    return (
                                        <Row key={key + a.id} className="my-3 me-1">
                                            <Col xs={4}>
                                                {key}:
                                            </Col>
                                            <Col className="border rounded bg-light">
                                                {key === "unavailable" ?  
                                                    mapAvailability(a.data[key]).map((val, i) => { 
                                                        return (<pre className="mb-0 text-prewrap" key={key + a.id + "_" + i}>{val}</pre>);
                                                    }) 
                                                : 
                                                    Array.isArray(a.data[key]) ? 
                                                        a.data[key].map((val, i) => { 
                                                            return (<pre className="mb-0 text-prewrap" key={key + a.id + "_" + i}>{val}</pre>);
                                                        }) 
                                                    : 
                                                        <pre className="mb-0 text-prewrap">{a.data[key].toString()}</pre>
                                                }
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </div>
                        </div>
                    </Col>
                );
            })}
        </Row>
        <Modal
            show={showHire}
            size="lg"
            aria-labelledby="hire-app-modal-title"
            backdrop="static"
            backdropClassName="session"
            className="session"
        >
            <Form onSubmit={hireApp}>
                <Modal.Header>
                    <Modal.Title id="hire-app-modal-title">
                        Hire Applicant
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row className="my-3">
                            <Col xs={12} lg className="mb-2 mb-lg-0">
                                <FloatingLabel controlId="editUserFirst" label="First">
                                    <Form.Control type="text" name="firstName" placeholder="First" required value={editUser?.firstName || ""} onChange={handleInput} />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} lg className="mb-2 mb-lg-0">
                                <FloatingLabel controlId="editUserMiddle" label="Middle">
                                    <Form.Control type="text" name="middleName" placeholder="Middle" value={editUser?.middleName || ""} onChange={handleInput} />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} lg>
                                <FloatingLabel controlId="editUserLast" label="Last">
                                    <Form.Control type="text" name="lastName" placeholder="Last" required value={editUser?.lastName || ""} onChange={handleInput} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col xs={12} lg className="mb-2 mb-lg-0">
                                <FloatingLabel controlId="editUserEmail" label="Email">
                                    <Form.Control type="email" name="email" placeholder="Email" value={editUser?.email || ""} onChange={handleInput} />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} lg>
                                <FloatingLabel controlId="editUserPhone" label="Phone">
                                    <Form.Control type="phone" name="phone" placeholder="Phone" value={editUser?.phone || ""} onChange={handleInput} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className="my-3">
                            {(curUser.accessLvl >= 2) && <>
                                <Col xs={12} lg className="mb-2 mb-lg-0">
                                    <FloatingLabel controlId="editUserPaychexId" label="Paychex ID">
                                        <Form.Control type="text" name="paychexId" placeholder="Paychex ID" value={editUser?.paychexId || ""} onChange={handleInput} />
                                    </FloatingLabel>
                                </Col>
                            </>}
                        </Row>
                        <Row className={{"my-5 align-items-center": true}}>
                            <Col xs={12} lg className="mb-2 mb-lg-0">
                                <FloatingLabel controlId="editUserUsername" label="Username">
                                    <Form.Control type="text" required name="username" placeholder="Username" value={editUser?.username || ""} onChange={handleInput} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <Row className={{"my-5 align-items-center": true}}>
                            <Col xs={12} lg className="mb-2 mb-lg-0">
                                <FloatingLabel controlId="editUserPosition" label="Position">
                                    <Form.Select name="positionId" aria-label="Position" defaultValue={editUser?.positionId} onChange={(e) => {
                                        setEditUser({
                                            ...editUser,
                                            positionId: e.target.value,
                                            payrate: e.target.selectedOptions[0].dataset.defaultpayrate
                                        });
                                    }}>
                                        {events.length && events.map(function(event) {
                                            if(!eventFilter || eventFilter === "" || eventFilter === event.id.toString()) {
                                                return (
                                                    <optgroup key={event.id} label={event.name}>
                                                        {event.positions?.length && event.positions.map(function(position) {
                                                            return (
                                                                <option key={position.id} value={position.id} data-defaultpayrate={position.defaultPayRate}>{position.name}</option>
                                                            );
                                                        })}
                                                    </optgroup>
                                                );
                                            } else {
                                                return(<React.Fragment key={event.id}></React.Fragment>);
                                            }
                                        })}
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} lg className="mb-2 mb-lg-0">
                                <FloatingLabel controlId="editUserPayrate" label="Payrate">
                                    <Form.Control type="text" required name="payrate" placeholder="Payrate" value={editUser?.payrate || ""} onChange={handleInput} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <div></div>
                    <div>
                        <Button variant="primary" type="submit" className="me-2">Hire</Button>
                        <Button variant="secondary" onClick={closeHireModal}>Cancel</Button>
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    </>);
}