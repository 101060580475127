import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { RequireAuth, Logout } from './_useAuth';

import MainLayout from './components/layouts/main';
import EmployeeLayout from './components/layouts/employee';
import Home from './components/home';
import Apply from './components/apply';
import News from './components/news';
import Policies from './components/policies';
import Employee from './components/employee';
import Login from './components/employee/login';
import Timeclock from './components/employee/timeclock';
import Todo from './components/employee/todo';
import Admin from './components/employee/admin';
import Scanner from './components/scanner';
import PrintBadges from './components/employee/admin/printBadges';
import MyAccount from './components/employee/myaccount';

function MyRoutes() {
    return (
        <Routes>
            <Route path="/" element={<MainLayout />}>
                <Route index element={<Home />} />
                <Route path="apply" element={<Apply />} />
                <Route path="news" element={<News />} />
                <Route path="policies" element={<Policies />} />
            </Route>

            <Route path="/employee" element={<EmployeeLayout />}>
                <Route index element={<RequireAuth><Employee /></RequireAuth>} />
                <Route path="timeclock" element={<RequireAuth><Timeclock /></RequireAuth>} />
                <Route path="todo" element={<RequireAuth><Todo /></RequireAuth>} />
                <Route path="admin" element={<RequireAuth><Admin /></RequireAuth>} />
                <Route path="login" element={<Login />} />
                <Route path="logout" element={<Logout />} />
                <Route path="myaccount" element={<RequireAuth><MyAccount /></RequireAuth>} />
            </Route>

            {/* ONE OFF PAGES */}
            <Route path="/scanner" element={<Scanner />} />

            {/* REDIRECT CATCHES */}
            <Route path="/login" element={<Navigate to="/employee/login" replace />} />
            <Route path="/logout" element={<Navigate to="/employee/logout" replace />} />
            <Route path="/employees" element={<Navigate to="/employee" replace />} />
            <Route path="/admin" element={<Navigate to="/employee/admin" replace />} />
            <Route path="/timeclock" element={<Navigate to="/employee/timeclock" replace />} />

            {/* CATCH ALL */}
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );
}

export default MyRoutes;