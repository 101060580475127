import React from 'react';
import { Container, Modal, Button } from 'react-bootstrap';

const alertContext = React.createContext();

function useProvideAlert() {    
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertTitle, setAlertTitle] = React.useState("");
    const [alertBody, setAlertBody] = React.useState(<></>);
    const [alertCB, setAlertCB] = React.useState(() => () => setShowAlert(false));

    const [showConfirm, setShowConfirm] = React.useState(false);
    const [confirmTitle, setConfirmTitle] = React.useState("");
    const [confirmBody, setConfirmBody] = React.useState(<></>);
    const [confirmCB, setConfirmCB] = React.useState(() => () => setShowConfirm(false));
    const [cancelCB, setCancelCB] = React.useState(() => () => setShowConfirm(false));

    const alert = React.useCallback(function(title, body, cb) {
        setAlertTitle(title);
        setAlertBody(body);
        setAlertCB(() => () => { setShowAlert(false); cb(); });
        setShowAlert(true);
    }, []);

    const confirm = React.useCallback(function(title, body, cb1, cb2) {
        setConfirmTitle(title);
        setConfirmBody(body);
        setConfirmCB(() => () => { setShowConfirm(false); cb1(); });
        setCancelCB(() => () => { setShowConfirm(false); cb2(); });
        setShowConfirm(true);
    },[]);

    return {
        showAlert, showConfirm,
        alertTitle, alertBody, alertCB,
        confirmTitle, confirmBody, confirmCB, cancelCB,
        alert, confirm
    };
}

export default function AlertConsumer() {
    return React.useContext(alertContext);
}

export function useAlert() {
    return React.useContext(alertContext);
};

export function AlertProvider({ children }) {
    const alert = useProvideAlert();

    return (<>
        <alertContext.Provider value={alert}>
            {children}
        </alertContext.Provider>
    </>);
}

export function AlertModal() {
    const { showAlert, alertTitle, alertBody, alertCB } = useAlert();

    return (<Modal
        show={showAlert}
        size="lg"
        aria-labelledby="alert-modal-title"
        backdrop="static"
        backdropClassName="session"
        className="session"
    >
        <Modal.Header>
            <Modal.Title id="alert-modal-title">
                {alertTitle}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container fluid>
                {alertBody}
            </Container>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
            <Button variant="primary" onClick={alertCB}>Okay</Button>
        </Modal.Footer>
    </Modal>);
};

export function ConfirmModal() {
    const { confirmTitle, confirmBody, showConfirm, confirmCB, cancelCB } = useAlert();

    return (<Modal
        show={showConfirm}
        size="lg"
        aria-labelledby="confirm-modal-title"
        backdrop="static"
        backdropClassName="session"
        className="session"
    >
        <Modal.Header>
            <Modal.Title id="confirm-modal-title">
                {confirmTitle}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container fluid>
                {confirmBody}
            </Container>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
            <Button variant="primary" onClick={confirmCB}>Confirm</Button>
            <Button variant="secondary" onClick={cancelCB}>Cancel</Button>
        </Modal.Footer>
    </Modal>);
};