import * as React from 'react';
import {
    Container, Tabs, Tab, Nav, Row, Col
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../../../_useAuth';
import Dashboard from './dashboard';
import Employees from './employees';
import Timesheets from './timesheets';
import Festival from './festival';
import Events from './events';
import Positions from './positions';
import Applications from './applications';

export default function Admin() {
    const { curUser, curFest } = useAuth();

    return (<>
        <Container fluid id="adminContainer">
            <Tab.Container defaultActiveKey="dashboard" id="admin-tabs" mountOnEnter unmountOnExit>
                <Row>
                    <Col xs="auto" className="border-right px-0 px-lg-2 py-3" style={{"minHeight": "calc(100vh - 127px)"}}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item><Nav.Link eventKey="dashboard" href="#">
                                <FontAwesomeIcon fixedWidth  icon="fa-solid fa-table" size="xs" />
                                <span className="d-none d-md-inline">&emsp;Dashboard</span>
                            </Nav.Link></Nav.Item>
                            {(curUser.accessLvl >= 3) && <Nav.Item><Nav.Link eventKey="general" href="#">
                                <FontAwesomeIcon fixedWidth  icon="fa-solid fa-gears" size="xs" />
                                <span className="d-none d-md-inline">&emsp;General</span>
                            </Nav.Link></Nav.Item> }
                            {(curUser.accessLvl >= 3) && <Nav.Item><Nav.Link eventKey="events" href="#">
                                <FontAwesomeIcon fixedWidth  icon="fa-solid fa-calendar-check" size="xs" />
                                <span className="d-none d-md-inline">&emsp;Events</span>
                            </Nav.Link></Nav.Item> }
                            {(curUser.accessLvl >= 3) && <Nav.Item><Nav.Link eventKey="positions" href="#">
                                <FontAwesomeIcon fixedWidth  icon="fa-solid fa-users-gear" size="xs" />
                                <span className="d-none d-md-inline">&emsp;Positions</span>
                            </Nav.Link></Nav.Item> }
                            <Nav.Item><Nav.Link eventKey="users" href="#">
                                <FontAwesomeIcon fixedWidth  icon="fa-solid fa-user" size="xs" />
                                <span className="d-none d-md-inline">&emsp;Employees</span>
                            </Nav.Link></Nav.Item>
                            {curFest?.enableApps && <Nav.Item><Nav.Link eventKey="apps" href="#">
                                <FontAwesomeIcon fixedWidth  icon="fa-solid fa-list-check" size="xs" />
                                <span className="d-none d-md-inline">&emsp;Applications</span>
                            </Nav.Link></Nav.Item>}
                            {(curUser.accessLvl >= 2) && <Nav.Item><Nav.Link eventKey="timesheets" href="#">
                                <FontAwesomeIcon fixedWidth  icon="fa-solid fa-file-export" size="xs" />
                                <span className="d-none d-md-inline">&emsp;Timesheets</span>
                            </Nav.Link></Nav.Item> }
                        </Nav>
                    </Col>
                    <Col style={{"maxHeight": "calc(100vh - 127px)", "overflow": "auto"}}>
                        <Tab.Content>
                            <Tab.Pane eventKey="dashboard"><Dashboard /></Tab.Pane>
                            {(curUser.accessLvl >= 3) && <Tab.Pane eventKey="general"><Festival/></Tab.Pane> }
                            {(curUser.accessLvl >= 3) && <Tab.Pane eventKey="events"><Events /></Tab.Pane> }
                            {(curUser.accessLvl >= 3) && <Tab.Pane eventKey="positions"><Positions /></Tab.Pane> }
                            <Tab.Pane eventKey="users"><Employees /></Tab.Pane>
                            <Tab.Pane eventKey="apps"><Applications /></Tab.Pane>
                            {(curUser.accessLvl >= 2) && <Tab.Pane eventKey="timesheets"><Timesheets /></Tab.Pane> }
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </Container>
    </>);
}