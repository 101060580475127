import * as React from 'react';
import { useAlert } from '../../_useAlert';
import {
    Container, Row, Col, Modal,
    Button, Form, FloatingLabel
} from 'react-bootstrap';
import Cropper from 'react-easy-crop';

function readFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result), false);
        reader.readAsDataURL(file);
    });
}

async function cropImage(imageSrc, pixelCrop, newW, newH) {
    if(!imageSrc || !pixelCrop) {
        return null;
    }

    const createImage = (url) => new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', (error) => reject(error))
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
    });

    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        return null;
    }

    canvas.width = image.width;
    canvas.height = image.height;

    ctx.drawImage(image, 0, 0);

    const data = ctx.getImageData(
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height
    );

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(data, 0, 0);

    const croppedImage = await createImage(canvas.toDataURL('image/jpeg'));

    canvas.width=newW;
    canvas.height=newH;
    ctx.drawImage(croppedImage, 0, 0, newW, newH);

    return canvas.toDataURL('image/jpeg');
}

export default function PhotoModal(props) {
    const { alert, confirm } = useAlert();
    const [crop, setCrop] = React.useState({ x: 0, y: 0 });
    const [zoom, setZoom] = React.useState(1);
    const [imageSrc, setImageSrc] = React.useState(null);
    const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);

    const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            let imageDataUrl = await readFile(file);
            setImageSrc(imageDataUrl);
        }
    }

    return (
        <Modal
            show={props.show}
            size="xl"
            aria-labelledby="photo-modal-title"
            backdropClassName="session"
            className="session"
            onHide={props.close}
        >
            <Modal.Header closeButton>
                <Modal.Title id="photo-modal-title">
                    Choose Photo
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="justify-content-center">
                    <Col xs={6}>
                        <div className="d-grid">
                            <label className="btn btn-outline-success">
                                Select Image
                                <input type="file" onChange={onFileChange} accept="image/*" hidden />
                            </label>
                        </div>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        {imageSrc ? 
                            <div style={{"position": "relative", "height": "500px"}}>
                                <Cropper
                                    image={imageSrc}
                                    crop={crop}
                                    zoom={zoom}
                                    zoomSpeed={0.25}
                                    aspect={props.aspect || 1}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                />
                            </div> 
                        : <></> }
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Button variant="primary" onClick={async (e) => { e.preventDefault(); props.savePhoto(await cropImage(imageSrc, croppedAreaPixels, props.newW || 250, props.newH || 250)) }}>Save</Button>
                <Button variant="secondary" onClick={props.close}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
}