import * as React from 'react';
import {
    Row, Col
} from 'react-bootstrap';
import useAuth from '../../../_useAuth';

export default function Dashboard() {
    const { curFest } = useAuth();

    const [empCountActive, setEmpCountActive] = React.useState(0);
    const [empCountClocked, setEmpCountClocked] = React.useState(0);
    const [empCountInactive, setEmpCountInactive] = React.useState(0);

    const [appCountNew, setAppCountNew] = React.useState(0);
    const [appCountHeld, setAppCountHeld] = React.useState(0);
    const [appCountRejected, setAppCountRejected] = React.useState(0);

    const [todoCountComplete, setTodoCountComplete] = React.useState(0);
    const [todoCountIncomplete, setTodoCountIncomplete] = React.useState(0);
    
    React.useEffect(function() {
        // active employee count
        fetch('/api/users/count?' + new URLSearchParams({"where": JSON.stringify({ "isActive": true })}), { method: "GET" })
        .then((res) => { if(!res.ok) { throw new Error(res.status + " " + res.statusText); } else { return res.json(); } })
        .then((result) => { setEmpCountActive(result.count || 0); }, (error) => { console.log(error); });

        // clocked in employee count
        fetch('/api/timeclock/count?' + new URLSearchParams({"where": JSON.stringify({ "endTime":  {"eq": null} })}), { method: "GET" })
        .then((res) => { if(!res.ok) { throw new Error(res.status + " " + res.statusText); } else { return res.json(); } })
        .then((result) => { setEmpCountClocked(result.count || 0); }, (error) => { console.log(error); });

        // inactive employee count
        fetch('/api/users/count?' + new URLSearchParams({"where": JSON.stringify({ "isActive": false })}), { method: "GET" })
        .then((res) => { if(!res.ok) { throw new Error(res.status + " " + res.statusText); } else { return res.json(); } })
        .then((result) => { setEmpCountInactive(result.count || 0); }, (error) => { console.log(error); });

        if(curFest.enableApps) {
            // new application count
            fetch('/api/applications/count?' + new URLSearchParams({"where": JSON.stringify({ "status": "new" })}), { method: "GET" })
            .then((res) => { if(!res.ok) { throw new Error(res.status + " " + res.statusText); } else { return res.json(); } })
            .then((result) => { setAppCountNew(result.count || 0); }, (error) => { console.log(error); });

            // held application count
            fetch('/api/applications/count?' + new URLSearchParams({"where": JSON.stringify({ "status": "held" })}), { method: "GET" })
            .then((res) => { if(!res.ok) { throw new Error(res.status + " " + res.statusText); } else { return res.json(); } })
            .then((result) => { setAppCountHeld(result.count || 0); }, (error) => { console.log(error); });

            // rejected application count
            fetch('/api/applications/count?' + new URLSearchParams({"where": JSON.stringify({ "status": "rejected" })}), { method: "GET" })
            .then((res) => { if(!res.ok) { throw new Error(res.status + " " + res.statusText); } else { return res.json(); } })
            .then((result) => { setAppCountRejected(result.count || 0); }, (error) => { console.log(error); });
        }
    }, []);

    return (<>
        <Row className="justify-content-center">
            <Col xxl={4} lg={6} xs={12} className="py-3">
                <div className="cell">
                    <Row className="justify-content-center my-3">
                        <Col xs="auto">
                            <span className="fs-1 fw-bold">Employees</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <span className="fs-huge fw-bold text-success">{empCountActive}</span>
                        </Col>
                        <Col className="text-center">
                            <span className="fs-huge fw-bold text-primary">{empCountClocked}</span>
                        </Col>
                        <Col className="text-center">
                            <span className="fs-huge fw-bold text-danger">{empCountInactive}</span>
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col className="text-center">
                            <span className="fs-5">Active</span>
                        </Col>
                        <Col className="text-center">
                            <span className="fs-5">Clocked In</span>
                        </Col>
                        <Col className="text-center">
                            <span className="fs-5">Inactive</span>
                        </Col>
                    </Row>
                </div>
            </Col>
            {curFest.enableApps && <Col xxl={4} lg={6} xs={12} className="py-3">
                <div className="cell">
                    <Row className="justify-content-center my-3">
                        <Col xs="auto">
                            <span className="fs-1 fw-bold">Applications</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <span className="fs-huge fw-bold text-primary">{appCountNew}</span>
                        </Col>
                        <Col className="text-center">
                            <span className="fs-huge fw-bold text-warning">{appCountHeld}</span>
                        </Col>
                        <Col className="text-center">
                            <span className="fs-huge fw-bold text-danger">{appCountRejected}</span>
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col className="text-center">
                            <span className="fs-5">Pending</span>
                        </Col>
                        <Col className="text-center">
                            <span className="fs-5">Held</span>
                        </Col>
                        <Col className="text-center">
                            <span className="fs-5">Rejected</span>
                        </Col>
                    </Row>
                </div>
            </Col>}
            {curFest.enableTodos && <Col xxl={4} lg={6} md={12} xs={12} className="py-3">
                <div className="cell">
                    <Row className="justify-content-center my-3">
                        <Col xs="auto">
                            <span className="fs-1 fw-bold">ToDos</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <span className="fs-huge fw-bold text-success">{todoCountComplete}</span>
                        </Col>
                        <Col className="text-center">
                            <span className="fs-huge fw-bold text-danger">{todoCountIncomplete}</span>
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col className="text-center">
                            <span className="fs-5">Complete</span>
                        </Col>
                        <Col className="text-center">
                            <span className="fs-5">Incomplete</span>
                        </Col>
                    </Row>
                </div>
            </Col>}
        </Row>
    </>);
}