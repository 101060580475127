export default function useHelpers() {
    return {
        round2: function(value) {
            return Math.round((value + Number.EPSILON) * 100) / 100;
        },
        formatCurrency: function(value) {
            var numericAmount = typeof value === 'string' ? parseFloat(value) : value;
            var pennies = Math.round((numericAmount + Number.EPSILON) * 100);
            var cents = pennies % 100;
            var dollars = Math.round(pennies / 100 - cents / 100);
            var centsStr = '' + cents;
            var result = '$' + dollars + '.' + ( centsStr.length < 2 ? '0' + cents : cents);

            return result;
        }
    };
}

