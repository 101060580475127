import * as React from 'react';
import {
    Container, Row, Col
} from 'react-bootstrap';
import { useAuth } from '../../_useAuth';

export default function Employee() {
    const { curUser, curFest } = useAuth();

    return (<>
        <Container fluid>
            <Row className="justify-content-center mt-5">
                <Col xs={6}>
                    <Row className="my-5">
                        <Col>
                            <h1>Welcome {curUser.username}!</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>{curFest.welcomeMsg}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </>);
}