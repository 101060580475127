import * as React from 'react';
import {
    Row, Col, Button,
    Form, FloatingLabel, InputGroup
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAlert } from '../../../_useAlert';
import useHelpers from '../../../_useHelpers';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Timesheets() {
    const { alert, confirm } = useAlert();
    const { round2, formatCurrency } = useHelpers();
    const [allTimesheets, setAllTimesheets] = React.useState([]);
    const [timesheets, setTimesheets] = React.useState([]);
    const [filteredTimesheets, setFilteredTimesheets] = React.useState([]);
    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [events, setEvents] = React.useState([]);
    const [filter, setFilter] = React.useState("");
    const [eventFilter, setEventFilter] = React.useState("");
    const [positionFilter, setPositionFilter] = React.useState("");
    const [hoursFilter, setHoursFilter] = React.useState("");
    const [hoursFilterLogic, setHoursFilterLogic] = React.useState("");
    const [tipsFilter, setTipsFilter] = React.useState("");
    const [tipsFilterLogic, setTipsFilterLogic] = React.useState("");

    function calcHours(start, end) {
        return round2((new Date(end || new Date()).getTime() - new Date(start).getTime()) / (1000 * 3600));
    }

    const refreshEventsPositions = React.useCallback(function() {
        fetch('/api/events?' + new URLSearchParams({"filter": JSON.stringify({"include":["positions"]})}), { method: "GET" })
        .then((res) => {if(!res.ok) {throw new Error(res.status + " " + res.statusText);} else {return res.json();}})
        .then((result) => {setEvents(result);},(error) => {console.log(error);});
    }, []);

    const updateFilter = React.useCallback(function(e) {
        var f = filter;
        if(e) {
            setFilter(e.target.value);
            f = e.target.value;
        }

        const attrs = [
            "note",
            "user.firstName",
            "user.middleName",
            "user.lastName",
            "user.email",
            "user.username",
            "user.paychexId"
        ];

        setFilteredTimesheets(timesheets.filter(function(timesheet) {
            var found = false;
            attrs.forEach(function(attr) {
                var subattrs = attr.split(".");
                var val = "";
                if(subattrs.length === 1) {
                    val = timesheet?.[subattrs[0]];
                } else if(subattrs.length === 2) {
                    val = timesheet?.[subattrs[0]]?.[subattrs[1]];
                } else {
                    val = timesheet?.[attr];
                }

                if(typeof val === 'string' && val.toLowerCase().indexOf(f.toLowerCase()) !== -1) {
                    found = true;
                }
            });

            return found;
        }));
    }, [timesheets, filter]);

    function updateEventFilter(e) {
        setEventFilter(e.target.value);
    }

    function updatePositionFilter(e) {
        setPositionFilter(e.target.value);
    }

    function updateHoursFilterLogic(e) {
        setHoursFilterLogic(e.target.value);
    }

    function updateHoursFilter(e) {
        setHoursFilter(e.target.value);
    }

    function updateTipsFilterLogic(e) {
        setTipsFilterLogic(e.target.value);
    }

    function updateTipsFilter(e) {
        setTipsFilter(e.target.value);
    }

    function downloadCSV() {
        confirm("Exporting Timesheets to Paychex CSV",
            <Row>
                <Col>
                    Any time records with 0 or fewer hours worked will be excluded from this export. Please confirm in the list below that all records have correct start and end times. Do you wish to continue? 
                </Col>
            </Row>,
            () => {
                if(filteredTimesheets.length) {
                    var CSV_STR = "Export Range: " + format(new Date(startDate + " 00:00"), 'M/d/yyyy') + " through " + format(new Date(endDate + " 23:59"), 'M/d/yyyy') + "\r\n\r\nRecord,Date,Clock In,Clock Out,Employee,ID,Duration,Rate,Deductions,Tips\r\n";
                    filteredTimesheets.forEach((timesheet, index) => {
                        const hours = round2((new Date(timesheet.endTime || new Date()).getTime() - new Date(timesheet.startTime).getTime()) / (1000 * 3600));
        
                        if(hours > 0) {
                            CSV_STR += timesheet.id + ",";
                            CSV_STR += format(new Date(timesheet.startTime), 'M/d/yyyy') + ",";
                            CSV_STR += format(new Date(timesheet.startTime), 'h:mm:ss a') + ",";
                            CSV_STR += format(new Date(timesheet.endTime), 'h:mm:ss a') + ",";
                            CSV_STR += "\"" + timesheet.user.firstName + " " + timesheet.user.middleName + " " + timesheet.user.lastName + "\",";
                            CSV_STR += (timesheet.user.paychexId ?? "000") + ",";
                            CSV_STR += hours + ",";
                            CSV_STR += timesheet.payrate + ",";
                            CSV_STR += (timesheet.deduction ?? "0") + ",";
                            CSV_STR += ((timesheet.cashTips ?? 0) + (timesheet.creditTips ?? 0)) + "\r\n";
                        }
                    });
        
                    const url = window.URL.createObjectURL(new Blob([CSV_STR], {type: 'text/csv'}));
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = format(new Date(startDate + " 00:00"), 'yyyyMMdd') + "-" + format(new Date(endDate + " 23:59"), 'yyyyMMdd') + "_fm_paychex_export.csv";
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                } else {
                    alert("No timesheets found",
                        <Row>
                            <Col>
                                Please select a different date range and try again.
                            </Col>
                        </Row>,
                        () => {}
                    );
                }
            },
            () => {}
        );
    }

    React.useEffect(() => {
        // TODO: [FIX manual entry for timesheet exports] put this in a setTimeout and clear the existing timeout first. 
        if(startDate && endDate) {
            var start = formatInTimeZone(new Date(startDate + " 00:00:00"), 'GMT', "yyyy-MM-dd'T'HH:mm:ss.SSSX");
            var end = formatInTimeZone(new Date(new Date(endDate + " 23:59:59")), 'GMT', "yyyy-MM-dd'T'HH:mm:ss.SSSX");

            // console.log(start);
            // console.log(end);

            // [6/19/2023] this is correct because start time and end tim combined logic would cause missed records since export filters enver overlap start/end dates
            fetch('/api/timeclock?' + new URLSearchParams({"filter": JSON.stringify(
                {"where": {"and": [{"endTime": {"gte": start}}, {"endTime": {"lte": end}}]}, "include": ["user"]}
            )}), { method: "GET" }).then(
                (res) => {
                    if(!res.ok) {
                        throw new Error(res.status + " " + res.statusText);
                    } else {
                        return res.json();
                    }
                }
            ).then(
                (result) => {
                    setAllTimesheets(result);
                    setTimesheets(result);
                },
                (error) => {
                    console.log(error);
                    alert("Something went wrong",
                        <Row>
                            <Col>
                                Unable to load timesheets. Please refresh the page or try again later.
                            </Col>
                        </Row>,
                        () => {}
                    );
                }
            );
        }
    }, [alert, startDate, endDate]);

    React.useEffect(() => {
        var filteredTimesheets = allTimesheets;

        if(eventFilter) {
            filteredTimesheets = filteredTimesheets.filter(function(timesheet) {
                var found = false;
                if(timesheet.positionName.toLowerCase().indexOf(eventFilter.toLowerCase()) !== -1) {
                    found = true;
                };
                return found;
            });
        }
        
        if(positionFilter) {
            filteredTimesheets = filteredTimesheets.filter(function(timesheet) {
                var found = false;
                if(timesheet.positionName.toLowerCase().indexOf(positionFilter.toLowerCase()) !== -1) {
                    found = true;
                };
                return found;
            });
        }

        if(hoursFilter) {
            filteredTimesheets = filteredTimesheets.filter(function(timesheet) {
                var found = false;
                switch (hoursFilterLogic) {
                    case "neq":
                        found = calcHours(timesheet.startTime, timesheet.endTime) !== Number(hoursFilter);
                        break;
                    case "lt":
                        found = calcHours(timesheet.startTime, timesheet.endTime) < Number(hoursFilter);
                        break;
                    case "gt":
                        found = calcHours(timesheet.startTime, timesheet.endTime) > Number(hoursFilter);
                        break;
                    case "eq":
                        // fall through
                    default:
                        found = calcHours(timesheet.startTime, timesheet.endTime) === Number(hoursFilter);
                        break;
                }
                return found;
            });
        }

        if(tipsFilter) {
            filteredTimesheets = filteredTimesheets.filter(function(timesheet) {
                var found = false;
                switch (tipsFilterLogic) {
                    case "neq":
                        found = timesheet.cashTips + timesheet.creditTips !== Number(tipsFilter);
                        break;
                    case "lt":
                        found = timesheet.cashTips + timesheet.creditTips < Number(tipsFilter);
                        break;
                    case "gt":
                        found = timesheet.cashTips + timesheet.creditTips > Number(tipsFilter);
                        break;
                    case "eq":
                        // fall through
                    default:
                        found = timesheet.cashTips + timesheet.creditTips === Number(tipsFilter);
                        break;
                }
                return found;
            });
        }

        setTimesheets(filteredTimesheets);
    }, [eventFilter, positionFilter, hoursFilterLogic, hoursFilter, tipsFilterLogic, tipsFilter, allTimesheets]);

    React.useEffect(() => {
        updateFilter();
    }, [updateFilter, timesheets]);

    React.useEffect(() => {
        refreshEventsPositions();
    }, [refreshEventsPositions]);

    return (<>
        <Row className="my-3">
            <Col>
                <h1>Timesheets</h1>
            </Col>
        </Row>
        <Row className="my-3 justify-content-end">
            <Col md>
                <FloatingLabel controlId="timesheetFilterStart" label="Start">
                    <Form.Control name="startDate" type="date" placeholder="Start" value={startDate} onChange={(e) => {setStartDate(e.target.value)}} />
                </FloatingLabel>
            </Col>
            <Col md className="my-3 my-md-0">
                <FloatingLabel controlId="timesheetFilterEnd" label="End">
                    <Form.Control name="endDate" type="date" placeholder="End" value={endDate} onChange={(e) => {setEndDate(e.target.value)}} />
                </FloatingLabel>
            </Col>
            <Col xs="auto">
                <Button variant="link" size="sm" className="link-primary text-decoration-none" onClick={downloadCSV}>
                    <FontAwesomeIcon icon="fa-solid fa-file-export" size="2x" />
                    <br/>Paychex Export
                </Button>
            </Col>
        </Row>
        <Row className="divider dotted"></Row>
        <Row className='justify-content-center align-items-center text-center my-4'>
            <Col xs="auto">
                <span className={"fw-bold" + ((filteredTimesheets?.length || 0) === (allTimesheets?.length || 0) ? " text-success" : "") + ((filteredTimesheets?.length || 0) !== (allTimesheets?.length || 0) ? " text-danger" : "")}>
                    SHOWING
                </span>
                <br/>
                <span className={"h3" + ((filteredTimesheets?.length || 0) === (allTimesheets?.length || 0) ? " text-success" : "") + ((filteredTimesheets?.length || 0) !== (allTimesheets?.length || 0) ? " text-danger" : "")}>
                    {filteredTimesheets?.length || 0}/{allTimesheets?.length || 0}
                </span>
            </Col>
            <Col xs>
                <FloatingLabel controlId="timesheetFilter" label="Filter">
                    <Form.Control type="text" name="filter" placeholder="Filter" value={filter} onChange={updateFilter} />
                </FloatingLabel>
            </Col>
        </Row>
        <Row className='justify-content-center align-items-center text-center my-4'>
            <Col xs={12} md={6} xl>
                <InputGroup>
                    <Form.Select className="flex-fill" aria-label="Hours Filter Logic" defaultValue="eq" onChange={updateHoursFilterLogic}>
                        <option value="eq">Equal To</option>
                        <option value="neq">Not Equal To</option>
                        <option value="lt">Less Than</option>
                        <option value="gt">More Than</option>
                    </Form.Select>
                    <FloatingLabel controlId="hoursFilter" label="Hours" className="flex-fill">
                        <Form.Control type="number" step="0.25" name="hoursFilter" placeholder="Hours" value={hoursFilter} onChange={updateHoursFilter} />
                    </FloatingLabel>
                </InputGroup>
            </Col>
            <Col xs={12} md={6} xl className="mt-3 mt-md-0">
                <InputGroup>
                    <Form.Select className="flex-fill" aria-label="Tips Filter Logic" defaultValue="eq" onChange={updateTipsFilterLogic}>
                        <option value="eq">Equal To</option>
                        <option value="neq">Not Equal To</option>
                        <option value="lt">Less Than</option>
                        <option value="gt">More Than</option>
                    </Form.Select>
                    <FloatingLabel controlId="tipsFilter" label="Total Tips" className="flex-fill">
                        <Form.Control type="number" step="0.25" name="tipsFilter" placeholder="Total Tips" value={tipsFilter} onChange={updateTipsFilter} />
                    </FloatingLabel>
                </InputGroup>
            </Col>
            <Col xs={6} lg className="mt-3 mt-xl-0">
                <FloatingLabel controlId="timesheetEventFilter" label="Event">
                    <Form.Select aria-label="Event Filter" onChange={updateEventFilter}>
                        <option value="">All Events</option>
                        {events.length && events.map(function(event) {
                            return (
                                <option key={event.id} value={event.name}>{event.name}</option>
                            );
                        })}
                    </Form.Select>
                </FloatingLabel>
            </Col>
            <Col xs={6} lg className="mt-3 mt-xl-0">
                <FloatingLabel controlId="timesheetPositionFilter" label="Position">
                    <Form.Select aria-label="Position Filter" onChange={updatePositionFilter}>
                        <option value="">All Positions</option>
                        {events.length && events.map(function(event) {
                            if(!eventFilter || eventFilter === "" || eventFilter.toLowerCase() === event.name.toLowerCase()) {
                                return (
                                    <optgroup key={event.id} label={event.name}>
                                        {event.positions?.length && event.positions.map(function(position) {
                                            return (
                                                <option key={position.id} value={event.name + " - " + position.name}>{position.name}</option>
                                            );
                                        })}
                                    </optgroup>
                                );
                            } else {
                                return(<React.Fragment key={event.id}></React.Fragment>);
                            }
                        })}
                    </Form.Select>
                </FloatingLabel>
            </Col>
        </Row>
        <Row className="my-3 pb-1 border-bottom border-dark fw-bold">
            <Col xs="auto">
                ID
            </Col>
            <Col>
                Employee
            </Col>
            <Col>
                Paychex ID
            </Col>
            <Col lg={4} className="my-3 my-lg-0">
                Clockin - Clockout
            </Col>
            <Col>
                Hours
            </Col>
            <Col>
                Position (Pay Rate)
            </Col>
            <Col className="text-end">
                Pay
            </Col>
        </Row>
        {filteredTimesheets.length ? filteredTimesheets.map(function(timesheet, index) {
            const hours = calcHours(timesheet.startTime, timesheet.endTime);

            return (
                <Row key={index} className="my-3 pb-1 border-bottom">
                    <Col xs="auto">
                        {timesheet.id}
                    </Col>
                    <Col>
                        {timesheet.user.firstName} {timesheet.user.middleName} {timesheet.user.lastName}
                    </Col>
                    <Col>
                        {timesheet.user.paychexId || "000"}
                    </Col>
                    <Col lg={4} className="my-3 my-lg-0">
                        {timesheet.startTime && format(new Date(timesheet.startTime), 'M/d/yyyy h:mm:ss a')} - {timesheet.endTime && format(new Date(timesheet.endTime), 'M/d/yyyy h:mm:ss a')}
                    </Col>
                    <Col>
                        {hours} hours
                    </Col>
                    <Col>
                        {timesheet.positionName} ({formatCurrency(timesheet.payrate)}/hr)
                    </Col>
                    <Col className="text-end">
                        {!!timesheet.payrate && <>
                            <span className="fw-bold">Pay: {formatCurrency(hours * timesheet.payrate)}</span>
                            <br/>
                        </>}
                        {timesheet.isTipped && <>
                            <span className="text-success">Cash Tips: {formatCurrency(timesheet.cashTips ?? 0)}</span>
                            <br/>
                            <span className="text-success">Credit Tips: {formatCurrency(timesheet.creditTips ?? 0)}</span>
                            <br/>
                        </>}
                        {!!timesheet.deduction && <>
                            <span className="text-danger">Deductions: &minus;{formatCurrency(timesheet.deduction ?? 0)}</span>
                        </>}
                    </Col>
                    <Col xs={12}>
                        <p className="text-secondary text-prewrap">{timesheet.note}</p>
                    </Col>
                </Row>
            );
        }) : <></>}
    </>);
}