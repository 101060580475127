import * as React from 'react';
import {
    Container, Row, Col, Button, Modal, Form, FloatingLabel, ListGroup
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useHelpers from '../../../_useHelpers';
import { useAuth } from '../../../_useAuth';
import { useAlert } from '../../../_useAlert';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export default function TimesheetModal(props) {
    const { round2, formatCurrency } = useHelpers();
    const { alert, confirm } = useAlert();

    const [loading, setLoading] = React.useState(true);
    const [timesheets, setTimesheets] = React.useState([]);
    const [showEdit, setShowEdit] = React.useState(false);
    const [editTimesheet, setEditTimesheet] = React.useState(null);

    const refreshTimesheets = React.useCallback(function() {
        if(props.user) {
            fetch('/api/timeclock?' + new URLSearchParams({"filter": JSON.stringify({"order": "startTime DESC", "where": { "userId":  props.user?.id }})}), { method: "GET" }).then(
                (res) => {
                    if(!res.ok) {
                        throw new Error(res.status + " " + res.statusText);
                    } else {
                        return res.json();
                    }
                }
            ).then(
                (result) => {
                    setTimesheets(result);
                    setLoading(false);
                },
                (error) => {
                    console.log(error);
                    alert("Something went wrong",
                        <Row>
                            <Col>
                                Unable to load timesheets. Please refresh the page or try again later.
                            </Col>
                        </Row>,
                        () => {}
                    );
                }
            );
        }
    }, [alert, props.user]);

    React.useEffect(() => {
        setLoading(true);
        refreshTimesheets();
    }, [refreshTimesheets]);

    function clockout(timeclockId) {
        fetch('/api/timeclock/' + timeclockId, { 
            method: "PATCH" ,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "endTime": formatInTimeZone(new Date(), 'GMT', "yyyy-MM-dd'T'HH:mm:ss.SSSX")
            })
        }).then(
            (res) => {
                if(!res.ok) {
                    throw new Error(res.status + " " + res.statusText);
                } else {
                    return true;
                }
            }
        ).then(
            (success) => {
                setLoading(true);
                refreshTimesheets();
            },
            (error) => {
                console.log(error);
                alert("Something went wrong",
                    <Row>
                        <Col>
                            Unable to save timesheet. Please refresh the page or try again later.
                        </Col>
                    </Row>,
                    () => {}
                );
            }
        );
    }

    function openEditModal(timesheet) {
        setEditTimesheet(timesheet);
        setShowEdit(true);
    }

    function closeEditModal() {
        setEditTimesheet(null);
        setShowEdit(false);
    }

    function saveTimesheet() {
        if(!(editTimesheet && editTimesheet?.id)) {
            alert("Something went wrong",
                <Row>
                    <Col>
                        Unable save timesheet. Please refresh the page or try again later.
                    </Col>
                </Row>,
                () => {}
            );
            return;
        }
        
        fetch('/api/timeclock/' + editTimesheet.id, { 
            method: "PATCH" ,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "startTime": editTimesheet.startTime,
                "endTime": editTimesheet.endTime || undefined,
                "note": editTimesheet.note || "",
                "cashTips": parseFloat(editTimesheet.cashTips || 0),
                "creditTips": parseFloat(editTimesheet.creditTips || 0),
                "deduction": parseFloat(editTimesheet.deduction || 0)
            })
        }).then(
            (res) => {
                if(!res.ok) {
                    throw new Error(res.status + " " + res.statusText);
                } else {
                    return true;
                }
            }
        ).then(
            (success) => {
                refreshTimesheets();
                setEditTimesheet(null);
                setShowEdit(false);
            },
            (error) => {
                console.log(error);
                alert("Something went wrong",
                    <Row>
                        <Col>
                            Unable to save timesheet. Please refresh the page or try again later.
                        </Col>
                    </Row>,
                    () => {}
                );
            }
        );
    }

    function deleteTimesheet() {
        confirm("Are you sure?",
            <>
                <Row className="mb-3">
                    <Col>
                        Are you sure you want to delete this time entry? You cannot undo this action!
                    </Col>
                </Row>
            </>,
            () => {
                fetch('/api/timeclock/' + editTimesheet.id, { 
                    method: "DELETE" ,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(
                    (res) => {
                        if(!res.ok) {
                            throw new Error(res.status + " " + res.statusText);
                        } else {
                            return true;
                        }
                    }
                ).then(
                    (success) => {
                        refreshTimesheets();
                        setEditTimesheet(null);
                        setShowEdit(false);
                    },
                    (error) => {
                        console.log(error);
                        alert("Something went wrong",
                            <Row>
                                <Col>
                                    Unable to delete timesheet. Please refresh the page or try again later.
                                </Col>
                            </Row>,
                            () => {}
                        );
                    }
                );
            },
            () => {}
        );
    }

    function handleInput(e) {
        var key = e.target.getAttribute('name');
        var val = e.target.value;

        if(key === 'startTime' || key ==='endTime') {
            val = formatInTimeZone(new Date(e.target.value), 'GMT', "yyyy-MM-dd'T'HH:mm:ss.SSSX");
        }

        setEditTimesheet({
            ...editTimesheet,
            [key]: val
        });
    }

    function preAddEntry() {
        if(props.user?.userPositions.length > 1) {
            confirm("Make a selection",
                <>
                    <Row className="mb-3">
                        <Col>
                            Which position are you clocking in for?
                        </Col>
                    </Row>
                    {props.user?.userPositions && props.user?.userPositions.map(function(position, index) {
                        var pos = props.user?.positions.find(p => p.id === position.positionId);
                        return (<Form.Check 
                            key={index} 
                            id={"userPosition-" + position.id} 
                            type="radio" 
                            label={pos.name + " (" + formatCurrency(position.payrate) + "/hr)"}  
                            name="userposition" 
                            value={position.id} 
                            defaultChecked={index === 0} 
                        />);
                    })}
                </>,
                () => {
                    addEntry(document.querySelector('input[name="userposition"]:checked').value);
                },
                () => {}
            );
        } else {
            addEntry(props.user?.userPositions[0]?.id);
        }
    }

    function addEntry(upId) {
        fetch('/api/addTimeEntry', { 
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId: props.user?.id, upId: upId })
        }).then(
            (res) => {
                if(!res.ok) {
                    throw new Error(res.status + " " + res.statusText);
                } else {
                    return res.json();
                }
            }
        ).then(
            (result) => {
                setLoading(true);
                refreshTimesheets();
            },
            (error) => {
                console.log(error);
                alert("Something went wrong",
                    <Row>
                        <Col>
                            Unable to add entry. Please refresh the page or try again later.
                        </Col>
                    </Row>,
                    () => {}
                );
            }
        );
    }

    return (<>
        <Modal
            show={props.show}
            size="xl"
            aria-labelledby="timesheet-modal-title"
            backdropClassName="session"
            className="session"
            onHide={props.close}
        >
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title id="timesheet-modal-title">
                        Timesheet for {props.user?.firstName} {props.user?.middleName} {props.user?.lastName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        {loading ? <>
                            <Row className="my-3 justify-content-center fw-bold">
                                <Col xs="auto">
                                    Loading...
                                </Col>
                            </Row>
                        </> : <>
                            {timesheets.length ? 
                                !timesheets[0]?.endTime ? <>
                                    <Row className='justify-content-center mb-5'>
                                        <Col xs={8} sm={7} md={6} lg={5} xl={4}>
                                            <div className="d-grid">
                                                <Button variant="danger" size="lg" onClick={() => { clockout(timesheets[0]?.id) }}>Clock Out</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </> : <>
                                    <Row className='justify-content-center mb-5'>
                                        <Col xs={8} sm={7} md={6} lg={5} xl={4}>
                                            <div className="d-grid">
                                                <Button variant="success" size="lg" onClick={preAddEntry}>Clock In</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            : <>
                                <Row className='justify-content-center mb-5'>
                                    <Col xs={8} sm={7} md={6} lg={5} xl={4}>
                                        <div className="d-grid">
                                            <Button variant="success" size="lg" onClick={preAddEntry}>Clock In</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </>}
                            <Row>
                                <Col>
                                    <ListGroup>
                                        {timesheets.length ? timesheets.map(function(timesheet, index) {
                                            const hours = round2((new Date(timesheet.endTime || new Date()).getTime() - new Date(timesheet.startTime).getTime()) / (1000 * 3600));
                                            
                                            return(
                                                <ListGroup.Item key={index} className={{"bg-danger bg-opacity-50": !timesheet.endTime}}>
                                                    <Row>
                                                        <Col lg={5}>
                                                            {timesheet.startTime && format(new Date(timesheet.startTime), 'M/d/yyyy h:mm:ss a')} - {timesheet.endTime && format(new Date(timesheet.endTime), 'M/d/yyyy h:mm:ss a')}
                                                        </Col>
                                                        <Col lg>
                                                            {hours} Hours
                                                        </Col>
                                                        <Col lg>
                                                            {timesheet.positionName} {timesheet.payrate ? (formatCurrency(timesheet.payrate) + "/hr") : ""}
                                                        </Col>
                                                        <Col xs="auto" className="text-end">
                                                            {!!timesheet.payrate && <>
                                                                <span className="fw-bold">Pay: {formatCurrency(hours * timesheet.payrate)}</span>
                                                                <br/>
                                                            </>}
                                                            {timesheet.isTipped && <>
                                                                <span className="text-success">Cash Tips: {formatCurrency(timesheet.cashTips ?? 0)}</span>
                                                                <br/>
                                                                <span className="text-success">Credit Tips: {formatCurrency(timesheet.creditTips ?? 0)}</span>
                                                                <br/>
                                                            </>}
                                                            {!!timesheet.deduction && <>
                                                                <span className="text-danger">Deductions: &minus;{formatCurrency(timesheet.deduction ?? 0)}</span>
                                                            </>}
                                                        </Col>
                                                        <Col xs="auto" className="ms-auto">
                                                            <Button variant="link" onClick={() => { 
                                                                openEditModal({...timesheet});
                                                            }}>Edit</Button>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <p className="text-secondary text-prewrap">{timesheet.note}</p>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            );
                                        }) : <>
                                            <ListGroup.Item>
                                                <Row className="justify-content-center">
                                                    <Col xs="auto">
                                                        No Timesheets Found!
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        </>}
                                    </ListGroup>
                                </Col>
                            </Row>
                        </>}
                    </Container>
                </Modal.Body>
            </Form>
        </Modal>
        <Modal
            show={showEdit}
            size="lg"
            aria-labelledby="edit-timesheet-modal-title"
            backdrop="static"
            backdropClassName="session"
            className="session"
        >
            <Modal.Header>
                <Modal.Title id="edit-timesheet-modal-title">
                    Edit Timesheet
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row className="justify-content-center mb-3">
                        <Col xs="auto">
                            <span className="h5"><span className="text-capitalize">{editTimesheet?.positionName}</span> {editTimesheet?.payrate ? formatCurrency(editTimesheet?.payrate) + "/hr" : ""}</span>
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col lg>
                            <FloatingLabel controlId="editTimesheetStart" label="Start">
                                <Form.Control name="startTime" type="datetime-local" placeholder="Start" value={
                                    editTimesheet?.startTime ? format(new Date(editTimesheet?.startTime), "yyyy-MM-dd'T'HH:mm:ss.SSS") : ""
                                } onChange={handleInput} />
                            </FloatingLabel>
                        </Col>
                        <Col lg className="mt-3 mt-lg-0">
                            <FloatingLabel controlId="editTimesheetEnd" label="End">
                                <Form.Control name="endTime" type="datetime-local" placeholder="End" value={
                                    editTimesheet?.endTime ? format(new Date(editTimesheet?.endTime), "yyyy-MM-dd'T'HH:mm:ss.SSS") : ""
                                } onChange={handleInput} />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="my-3">
                        {editTimesheet?.isTipped && <>
                            <Col lg>
                                <FloatingLabel controlId="editTimesheetCashTips" label="Cash Tips">
                                    <Form.Control type="number" step="0.25" name="cashTips" placeholder="Cash Tips" value={editTimesheet?.cashTips ?? 0} onChange={handleInput} />
                                </FloatingLabel>
                            </Col>   
                            <Col lg className="mt-3 mt-lg-0">
                                <FloatingLabel controlId="editTimesheetCreditTips" label="Credit Tips">
                                    <Form.Control type="number" step="0.25" name="creditTips" placeholder="Credit Tips" value={editTimesheet?.creditTips ?? 0} onChange={handleInput} />
                                </FloatingLabel>
                            </Col>    
                        </>}
                        <Col lg className="mt-3 mt-lg-0">
                            <FloatingLabel controlId="editTimesheetDeductions" label="Deductions">
                                <Form.Control type="number" step="0.25" name="deduction" placeholder="Deductions" value={editTimesheet?.deduction ?? 0} onChange={handleInput} />
                            </FloatingLabel>
                        </Col>      
                    </Row>
                    <Row className="my-3">
                        <Col>
                            <FloatingLabel controlId="editTimesheetNote" label="Note">
                                <Form.Control as="textarea" name="note" placeholder="Note" style={{ height: '150px' }} value={editTimesheet?.note || ""} onChange={handleInput} />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-5">
                            <Col xs={6}>
                                <div className="d-grid">
                                    <Button type="button" variant="danger" onClick={deleteTimesheet}>Delete</Button>
                                </div>
                            </Col>
                        </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Button variant="primary" onClick={saveTimesheet}>Save</Button>
                <Button variant="secondary" onClick={closeEditModal}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    </>);
}