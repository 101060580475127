import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { 
    Container, Modal,
    Button, Form
} from 'react-bootstrap';

const authContext = React.createContext();

function useProvideAuth() {    
    const [isAuthed, setIsAuthed] = React.useState(false);
    const [curUser, setCurUser] = React.useState(null);
    const [curFest, setCurFest] = React.useState(null);
    const [fests, setFests] = React.useState([]);

    return {
        curUser, setCurUser,
        isAuthed, setIsAuthed,
        curFest, setCurFest,
        fests, setFests,
        updateLogin() {
            fetch('/api/users/me').then(
                (res) => {
                    if(res.ok) {
                        return res.json();
                    } else {
                        throw new Error(res.status + " " + res.statusText);
                    }
                }
            ).then(
                (result) => {
                    setCurUser(result);
                    return true;
                },
                (error) => {
                    console.log(error);
                    return false;
                }
            );
        },
        updateLoginFest() {
            fetch('/api/festivals').then(
                (res) => {
                    if(res.ok) {
                        return res.json();
                    } else {
                        throw new Error(res.status + " " + res.statusText)
                    }
                }
            ).then(
                (result) => {
                    setFests(result);
                    setCurFest(result.find(element => element.id === curFest.id));
                    return true;
                },
                (error) => {
                    console.log(error);
                    return false;
                }
            );
        },
        async checkAuthed() {
            var authed = await fetch('/api/users/whoAmI').then(
                (res) => {
                    if(res.ok) {
                        return res.text();
                    } else {
                        throw new Error(res.status + " " + res.statusText)
                    }
                }
            ).then(
                (result) => {
                    return fetch('/api/users/me').then(
                        (res) => {
                            if(res.ok) {
                                return res.json();
                            } else {
                                throw new Error(res.status + " " + res.statusText);
                            }
                        }
                    ).then(
                        (result) => {
                            return [true, result];
                        },
                        (error) => {
                            console.log(error);
                            return [false];
                        }
                    );
                },
                (error) => {
                    console.log(error);
                    return [false];
                }
            );
            
            setIsAuthed(authed[0]);

            if(authed[0]) {
                if(curUser === null) {
                    setCurUser(authed[1]);
                }
                
                if(authed[1].festival && authed[1].accessLvl < 3) {
                    setCurFest(authed[1].festival);
                    setFests([]);
                }
                
                if(!authed[1].festival || authed[1].accessLvl >= 3) {
                    var festivals = await fetch('/api/festivals').then(
                        (res) => {
                            if(res.ok) {
                                return res.json();
                            } else {
                                throw new Error(res.status + " " + res.statusText)
                            }
                        }
                    ).then(
                        (result) => {
                            return result;
                        },
                        (error) => {
                            console.log(error);
                            return [];
                        }
                    );
        
                    setCurFest(null);
                    setFests(festivals);
                }
            } else if(!authed[0] && curUser !== null) {
                setCurUser(null);
                setCurFest(null);
                setFests([]);
            }

            return authed[0];
        },
        login(username, password) {
            return new Promise((res) => {
                fetch('/api/users/login', {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "username": username,
                        "password": password
                    })
                }).then(
                    (res) => {
                        if(res.ok) {
                            return res.json();
                        } else {
                            throw new Error(res.status + " " + res.statusText);
                        }
                    }
                ).then(
                    (result) => {
                        fetch('/api/users/me').then(
                            (res) => {
                                if(res.ok) {
                                    return res.json();
                                } else {
                                    throw new Error(res.status + " " + res.statusText);
                                }
                            }
                        ).then(
                            (result) => {
                                setCurUser(result);
                                setCurFest(result.festival);
                                res(true);
                            },
                            (error) => {
                                console.log(error);
                                res(false);
                            }
                        );
                    },
                    (error) => {
                        console.log(error);
                        res(false);
                    }
                );
            });
        },
        setCookie(cname, cvalue, exdays) {
            const d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            let expires = "expires="+ d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
          }
    };
}

export default function AuthConsumer() {
    return React.useContext(authContext);
}

export function useAuth() {
    return React.useContext(authContext);
};

export function AuthProvider({ children }) {
    const auth = useProvideAuth();

    return (<>
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    </>);
}

export function RequireAuth({ children }) {
    const { checkAuthed, curFest, setCurFest, fests, setCookie, curUser } = useAuth();
    const location = useLocation();
    const [authed, setAuthed] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [gateKeeper, setGateKeeper] = React.useState(true);

    React.useEffect(() => {
        if(gateKeeper && loading) {
            checkAuthed().then((status) => {
                setAuthed(status);
                setLoading(false);
            });
        }
        setGateKeeper(false);
    }, [gateKeeper, loading, checkAuthed]);

    React.useEffect(() => {
        setCookie("FM_CUR_FEST", curFest?.id, 1);
    }, [curFest]);

    function updateFest() {
        const id = document.querySelector('input[name="festival"]:checked').value;
        setCurFest(fests.find(element => element.id === parseInt(id)));
    }

    return <>
        { authed && (curFest != null) && children }
        { !authed && !loading && <Navigate to="/login" replace state={{ path: location.pathname }} /> }
        { authed && curFest == null && fests?.length && <>
            <Modal
                show={true}
                size="lg"
                aria-labelledby="fest-modal-title"
                backdrop="static"
                backdropClassName="session"
                className="session"
            >
                <Modal.Header>
                    <Modal.Title id="fest-modal-title">
                        Choose a Festival
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        {fests && fests.map(function(fest, index) {
                            return (
                                <Form.Check key={index} id={"festival-" + fest.id} type="radio" label={fest.name} name="festival" value={fest.id} defaultChecked={index === 0} />
                            );
                        })}
                    </Container>
                </Modal.Body>
                <Modal.Footer className="justify-content-end">
                    <Button variant="primary" onClick={updateFest}>Okay</Button>
                </Modal.Footer>
            </Modal>
        </> }
    </>;
}

export function Logout() {
    const navigate = useNavigate();

    React.useEffect(() => {
        fetch('/api/users/logout').then(
            (res) => {
                if(!res.ok) {
                    throw new Error(res.status + " " + res.statusText);
                }
            }
        ).then(
            () => {
                navigate("/employee");
            },
            (error) => {
                console.log(error);
                navigate("/employee");
            }
        );
    }, [navigate]);

    return <></>;
}

export function SessionManager() {
    const navigate = useNavigate();
    const [expiresIn, setExpiresIn] = React.useState(null); // minutes

    React.useEffect(() => {
        checkSession();
        const interval = setInterval(() => {
            checkSession();
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    function checkSession() {
        const cookieValue = document.cookie
            .split('; ')
            ?.find(row => row.startsWith('FM_SESS='))
            ?.split('=')?.[1] || document.cookie
            .split('; ')
            ?.find(row => row.startsWith('DEV_FM_SESS='))
            ?.split('=')?.[1];

        if(cookieValue) {            
            const mins = ((parseInt(cookieValue) - Date.now()) / 1000) / 60;
            setExpiresIn(Math.floor(mins));
        }
    }

    const refreshSession = () => {
        fetch('/api/users/refresh-login', { method: "POST" }).then(
            (res) => {
                if(!res.ok) {
                    throw new Error(res.status + " " + res.statusText);
                }
            }
        ).then(
            () => {
                checkSession();
            },
            (error) => {
                console.log(error);
                alert("Unable to refresh session. Please login again.");
                navigate("/logout");
            }
        );
    }

    const endSession = () => {
        setExpiresIn(null);
        navigate("/logout");
    }

    return (<Modal
        show={expiresIn !== null && expiresIn <= 5}
        size="lg"
        aria-labelledby="session-modal-title"
        backdrop="static"
        backdropClassName="session"
        className="session"
    >
        <Modal.Header>
            <Modal.Title id="session-modal-title">
                Session Warning
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container fluid>
                {expiresIn > 0 &&<>
                    Your session will expire in <span className="fw-bold">{expiresIn} minutes</span>. Click <span className="fw-bold text-primary">Okay</span> to extend you session or <span className="fw-bold text-secondary">Cancel</span> to logout now.
                </>}
                {expiresIn <= 0 &&<>
                    Your session has expired. Please login again.
                </>}
            </Container>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
            {expiresIn > 0 &&<>
                <Button variant="primary" onClick={refreshSession}>Okay</Button>
                <Button variant="secondary" onClick={endSession}>Cancel</Button>
            </>}
            {expiresIn <= 0 &&<>
                <Button variant="primary" onClick={endSession}>Okay</Button>
            </>}
        </Modal.Footer>
    </Modal>);
};