import * as React from 'react';
import { Outlet, useLocation, useMatch } from 'react-router-dom';

import Brimstone from '../brimstonehaunt.com'

export default function MainLayout() {
    const location = useLocation();
    const isIndex = useMatch('/');

    return (
        <div id="main-layout" className={!isIndex && location.pathname !== '/' && ("navbar-short nothome")}>
            {!isIndex && location.pathname !== '/' && ( <Header /> )}
            <Outlet />
            <Footer />
        </div>
    );
}

export function Header() {
    const [content, setContent] = React.useState(null);

    React.useEffect(function() {
        let host = window.location.hostname;
        let chunks = host.split(".");

        if(chunks.includes("brimstonehaunt")) {
            setContent(<Brimstone.Header />);
        }
    }, []);

    return (<>{content}</>);
}

export function Footer() {
    const [content, setContent] = React.useState(null);

    React.useEffect(function() {
        let host = window.location.hostname;
        let chunks = host.split(".");

        if(chunks.includes("brimstonehaunt")) {
            setContent(<Brimstone.Footer />);
        }
    }, []);

    return (<>{content}</>);
}