import './main.scss';

import CalendarVert from "./img/2023_calendar_vert.png";
import CalendarHorz from "./img/2023_calendar_horz.png";

import Logo from "./img/logos/brimstone-haunt-logo_textured-2020.png";
import HayrideLogo from "./img/logos/haunted_hayride_color.png";
import ForestLogo from "./img/logos/forest_color.png";
import BogLogo from "./img/logos/the_bog_logo.png";
import PsychosisLogo from "./img/logos/psychosis.png";
import PaintLogo from "./img/logos/paintball.png";
import ZombieLogo from "./img/logos/zombie_assault.png";
import CrushLogo from "./img/logos/cranium_crusher.png";
import AxeLogo from "./img/logos/axe_throwing.png";
import RenFest from "./img/sponsors/renfest_logo.png";
import CelticFest from "./img/sponsors/celticfest_logo.png";
import BBQ from "./img/sponsors/bbq_logo.png";
import MtnDew from "./img/sponsors/mtn_dew_logo.png";
import Guinness from "./img/sponsors/LOGO_Guinness.png";
import TwistedTea from "./img/sponsors/TT_Logo_2020.png";
import Sonder from "./img/sponsors/Sonder_white_png.png";
import NowHiring from "./img/now-hiring.png";

import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Container, Row, Col, Button, Navbar, Nav, NavDropdown
} from 'react-bootstrap';
import $ from 'jquery';

var isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
	},
	SmallWindow: function() {
		return window.innerWidth < 768;
	},
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

export default function Home() {
    const [openingDate, setOpeningDate] = React.useState(new Date("Sep 13, 2024 19:00:00").getTime());
    const [timer, setTimer] = React.useState(null);
    const [days, setDays] = React.useState(0);
    const [hours, setHours] = React.useState(0);
    const [minutes, setMinutes] = React.useState(0);
    const [seconds, setSeconds] = React.useState(0);

    React.useEffect(function() {
        if(!isMobile.any()) {
            var tag = document.createElement('script');
            tag.id = 'iframe-demo';
            tag.src = 'https://www.youtube.com/iframe_api';
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }

        $(document).on("click", ".list-group-item-action", function() {
            $($(this).data("target")).slideToggle();
            $(this).toggleClass("active");
        }).on("scroll", function() {
            $("#main-layout").toggleClass("navbar-short", $(window).scrollTop() > 5);
        });

        setTimer(setInterval(timerUpdate, 1000));

        return function cleanup() {
            clearInterval(timer);
        };
    }, []);

    function scrollPageTo(target) {
        if($(window).scrollTop() <= 5) {
            $(window).scrollTop(6);
            setTimeout(function() {
                $('html, body').animate({
                    scrollTop: $(target).offset().top
                }, 250);
            }, 100);
        } else {
            $('html, body').animate({
                scrollTop: $(target).offset().top
            }, 250);
        }
    }

    function timerUpdate() {
        var now = new Date().getTime();
        var distance = openingDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        
        if(days < 0) days = 0;
        if(hours < 0) hours = 0;
        if(minutes < 0) minutes = 0;
        if(seconds < 0) seconds = 0;
        
        setDays(days);
        setHours(hours);
        setMinutes(minutes);
        setSeconds(seconds);
    }

    return (<>
        {/* {{!------------------------------------------------ ROLLER SCRIPT ---------------------------------------------------}} */}
        <script id="roller-checkout" src="https://cdn.rollerdigital.com/scripts/widget/checkout_iframe.js" data-checkout="https://ecom.roller.app/brimstonehaunt/tickets/en-us/home"></script>
        {/* {{!---------------------------------------------- END ROLLER SCRIPT -------------------------------------------------}} */}

        {/* {{!----------------------------------------- Q1 MEDIA RETARGETTING PIXEL --------------------------------------------}} */}
        <img height="1" width="1" style={{"borderStyle":"none", "position": "absolute", "top": "-100px", "left": "-100px"}} alt="retargetting pixel" src="https://insight.adsrvr.org/track/pxl/?adv=briyt8x&ct=0:pyvkipz&fmt=3"/>
        {/* {{!--------------------------------------- END Q1 MEDIA RETARGETTING PIXEL ------------------------------------------}} */}

        {/* TODO FIGURE OUT GOOGLE TAG THING */}

        {/* {{!--------------------------------------- HEADER ------------------------------------------}} */}
        <Navbar collapseOnSelect  variant="dark" expand="md" id="main-nav" className="fixed-top py-0 px-3">
            <Navbar.Brand href="/"><img alt ="Logo" src={Logo} /></Navbar.Brand>

            <Navbar.Toggle aria-controls="main-nav-menu" />

            <Navbar.Collapse id="main-nav-menu" className="flex-md-column">
                <Nav id="navbar-social-links" className="ms-auto justify-content-center">
                    <li className="nav-item ms-2">
                        <a href="http://www.facebook.com/brimstonehaunt" target="_blank">
                            <FontAwesomeIcon icon="fa-brands fa-facebook-f" className="fa fa-icon-small bg-transparent"></FontAwesomeIcon>
                        </a>
                    </li>
                    <li className="nav-item ms-2">
                        <a href="http://www.instagram.com/brimstonehaunt" target="_blank">
                            <FontAwesomeIcon icon="fa-brands fa-instagram" className="fa fa-icon-small bg-transparent"></FontAwesomeIcon>
                        </a>
                    </li>
                </Nav>
                <Nav className="ms-auto my-5 my-md-auto">
                    <Nav.Link className="d-inline-block d-md-none d-xl-inline-block" onClick={() => {scrollPageTo('#hayride')}} href='##'>Haunted Hayride</Nav.Link>
                    <Nav.Link className="d-inline-block d-md-none d-xl-inline-block" onClick={() => {scrollPageTo('#bog')}}  href='##'>The BOG</Nav.Link>
                    <Nav.Link className="d-inline-block d-md-none d-xl-inline-block" onClick={() => {scrollPageTo('#psychosis')}}  href='##'>Psychosis</Nav.Link>
                    <Nav.Link className="d-inline-block d-md-none d-xl-inline-block" onClick={() => {scrollPageTo('#midway')}}  href='##'>Midway</Nav.Link>
                    <NavDropdown title="Attractions" id="attractionDropdown" className="d-none d-md-inline-block d-xl-none">
                        <NavDropdown.Item onClick={() => {scrollPageTo('#hayride')}}>Haunted Hayride</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => {scrollPageTo('#bog')}}>The BOG</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => {scrollPageTo('#psychosis')}}>Psychosis</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => {scrollPageTo('#midway')}}>Midway</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link onClick={() => {scrollPageTo('#tickets')}} href='##'>Tickets</Nav.Link>
                    <Nav.Link onClick={() => {scrollPageTo('#schedule')}} href='##'>Schedule</Nav.Link>
                    <Nav.Link onClick={() => {scrollPageTo('#directions')}} href='##'>Directions</Nav.Link>
                    <Nav.Link onClick={() => {scrollPageTo('#faq')}} href='##'>FAQ</Nav.Link>

                    <li className="nav-item"><a className="nav-link py-md-0 text-md-right" href="/apply" title="Fill Out an Application"><img src={NowHiring} style={{"maxHeight": "40px"}} /></a></li>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
        {/* {{!--------------------------------------- END HEADER ------------------------------------------}} */}

        <Container fluid>
        {/* {{!----------------------------------------------------- HERO -------------------------------------------------------}} */}
            <div id="bg-video-player"></div>
            <div className="section full-height video-bg">
                <div className="section-content">
                    <Row className="h-100">
                        <Col lg={6} className="my-auto">
                            <Row className="justify-content-center">
                                <Col xs="auto">
                                    <Row>
                                        <Col xs="auto" className="h-font-lg text-uppercase">
                                            Reap Fear Every Friday &
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="auto" className="h-font-lg text-uppercase">
                                            Saturday Through October
                                        </Col>
                                    </Row>
                                    <Row className="mt-5">
                                        <Col xs="auto" className="h-font-md text-uppercase">
                                            Five terrifying attractions for all!
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} className="my-auto text-center">
                            <Button className="btn-lg rounded-pill py-md-3 px-5 text-white" onClick={() => { scrollPageTo('#tickets'); }}>
                                <FontAwesomeIcon icon="fa-solid fa-ticket" className="fa fa-icon-normal me-2 align-bottom" /> Tickets
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        {/* {{!--------------------------------------------------- END HERO -----------------------------------------------------}} */}

        {/* {{!-------------------------------------------------- COUNTDOWN -----------------------------------------------------}} */}
            <div className="parallax section dividers-red" data-background='bg_scratchy_red' data-stellar-background-ratio="0.25">
                <div className="section-heading">
                    <h2 className="shrink">
                        WE'RE SHARPENING OUR BLADES IN ANTICIPATION
                    </h2>
                </div>
                <div className="section-content mt-5">
                    <div className="row justify-content-center">
                        <div id="countdown-timer" className="col-auto">
                            <ul>
                                <li><span id="count-days" className="countdown-count">{days}</span><br/><span className="countdown-label">Days</span></li>
                                <li><span className="countdown-count">:</span></li>
                                <li><span id="count-hours" className="countdown-count">{hours}</span><br/><span className="countdown-label">Hours</span></li>
                                <li><span className="countdown-count">:</span></li>
                                <li><span id="count-minutes" className="countdown-count">{minutes}</span><br/><span className="countdown-label">Minutes</span></li>
                                <li><span className="countdown-count">:</span></li>
                                <li><span id="count-seconds" className="countdown-count">{seconds}</span><br/><span className="countdown-label">Seconds</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        {/* {{!------------------------------------------------ END COUNTDOWN ---------------------------------------------------}} */}

        {/* {{!---------------------------------------------- HAYRIDE ATTRACTION ------------------------------------------------}} */}
            <div id="hayride" className="anchor"></div>
            <div className="parallax section full-height" data-background='bg_hayride' data-stellar-background-ratio="0.25">
                <div className="section-content">
                    <div className="row h-100">
                        <div className="col my-auto">
                            <div className="row justify-content-center">
                                <div className="col-auto">
                                    <img src={HayrideLogo} alt="Brimstone Haunted Hayride Attraction Logo" width="300" />
                                </div>
                            </div>
                            <div className="row justify-content-center mt-5">
                                <div className="col-12 col-lg-9 col-xl-6 text-justify">
                                    <p>The Brimstone Farm was once a prosperous family farm. Now, it is a place full of long-lost memories, stories, and curses. Many horrors have been reported in these fields but not many are willing to talk about the events that occur in the corn. Come see what’s waiting for you around every twist and turn.</p>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col">
                                    <div className="row justify-content-center">
                                        <div className="col-auto">
                                            <h5>ATTRACTION INTENSITY</h5>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-auto text-danger">
                                            <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-large mx-1" />
                                            <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-large mx-1" />
                                            <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-large mx-1" />
                                            <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-large mx-1" />
                                            <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-large mx-1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {/* {{!-------------------------------------------- END HAYRIDE ATTRACTION ----------------------------------------------}} */}

        {/* {{!------------------------------------------ The Bog ATTRACTION -------------------------------------------}} */}
            <div id="bog" className="anchor"></div>
            <div className="parallax section full-height" data-background='bg_bog' data-stellar-background-ratio="0.25">
                <div className="section-content">
                    <div className="row h-100">
                        <div className="col my-auto">
                            <div className="row justify-content-center">
                                <div className="col-auto">
                                    <img src={BogLogo} alt="The BOG Attraction Logo" width="300" />
                                </div>
                            </div>
                            <div className="row justify-content-center mt-5">
                                <div className="col-12 col-lg-9 col-xl-6 text-justify">
                                    <p>Experience the rebirth of THE BOG, a petrifying legend brought back to life from the Springboro Haunted Hayride and Black Bog. In the shadowy town of Brimstone, a monstrous presence lurks, born from the depths of legends and fear. Beware the vile tendrils of black vines, slithering and seeking, as they spread their darkness. This season, Brimstone Haunt goes back to its roots and renames the Forgotten Forest to THE BOG! Will you emerge from the shadows, heart racing and breathless, to tell the tale of your encounter with THE BOG?</p>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col">
                                    <div className="row justify-content-center">
                                        <div className="col-auto">
                                            <h5>ATTRACTION INTENSITY</h5>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-auto text-danger">
                                            <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-large mx-1" />
                                            <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-large mx-1" />
                                            <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-large mx-1" />
                                            <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-large mx-1" />
                                            <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-large mx-1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {/* {{!---------------------------------------- END THE BOG ATTRACTION -----------------------------------------}} */}

        {/* {{!--------------------------------------------- PSYCHOSIS ATTRACTION -----------------------------------------------}} */}
            <div id="psychosis" className="anchor"></div>
            <div className="parallax section full-height" data-background='bg_psychosis' data-stellar-background-ratio="0.25">
                <div className="section-content">
                    <div className="row h-100">
                        <div className="col my-auto">
                            <div className="row justify-content-center">
                                <div className="col-auto">
                                    <img src={PsychosisLogo} alt="Psychosis Attraction Logo" width="300" />
                                </div>
                            </div>
                            <div className="row justify-content-center mt-5">
                                <div className="col-12 col-lg-9 col-xl-6 text-justify">
                                    <p>Around every dark corner awaits disturbing scenes of horror that will pull you further from reality. Get the yell scared out of you as you wind through the dark maze-like pathways trying to find a way out. Or surrender to the darkness and join the horror in a permanent state of Psychosis.</p>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col">
                                    <div className="row justify-content-center">
                                        <div className="col-auto">
                                            <h5>ATTRACTION INTENSITY</h5>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-auto text-danger">
                                            <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-large mx-1"></FontAwesomeIcon>
                                            <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-large mx-1"></FontAwesomeIcon>
                                            <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-large mx-1"></FontAwesomeIcon>
                                            <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-large mx-1"></FontAwesomeIcon>
                                            <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-large mx-1"></FontAwesomeIcon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {/* {{!------------------------------------------- END PSYCHOSIS ATTRACTION ---------------------------------------------}} */}

        {/* {{!---------------------------------------------- MIDWAY ATTRACTION -------------------------------------------------}} */}
            <div id="midway" className="anchor"></div>
            <div className="parallax section full-height-lg" data-background='midway' data-stellar-background-ratio="0.25">
                <div className="section-content">
                    <div className="row h-lg-100">
                        <div className="col">
                            <div className="row my-5">
                                <div className="col-12 col-lg-6">
                                    <div className="row justify-content-center">
                                        <div className="col-auto ">
                                            <img src={ZombieLogo} alt="Zombie Assault Attraction Logo" width="125" />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-4">
                                        <div className="col-12 col-lg-9 text-justify">
                                            <p>The zombie apocalypse is here. The infection has spread to every corner of the globe and the future seems bleak. There is a small refugee camp nearby that has managed to fight off the hoard and is heavily reinforced. Board our armored bus with a group of fellow survivors and a small assault team who will protect you in transit to the camp and see if you can survive the zombie assault.</p>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col">
                                            <div className="row justify-content-center">
                                                <div className="col-auto">
                                                    <h6>ATTRACTION INTENSITY</h6>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-auto text-danger">
                                                    <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="row justify-content-center">
                                        <div className="col-auto ">
                                            <img src={PaintLogo} alt="Dead Shot Paintball Attraction Logo" width="250" />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-4">
                                        <div className="col-12 col-lg-9 text-justify">
                                            <p>Take up arms against the zombie hoard. Test out your accuracy and reflexes against our army of zombies in this shooting gallery. Hurl paintballs at zombies as they dodge this way and that avoiding their colorful doom. Can you prevent an apocalypse or just paint a pretty picture?</p>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col">
                                            <div className="row justify-content-center">
                                                <div className="col-auto">
                                                    <h6>ATTRACTION INTENSITY</h6>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-auto text-danger">
                                                    <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-5">
                                <div className="col-12 col-lg-6">
                                    <div className="row justify-content-center">
                                        <div className="col-auto ">
                                            <img src={CrushLogo} alt="Cranium Crusher Attraction Logo" width="300" />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-4">
                                        <div className="col-12 col-lg-9 text-justify">
                                            <p>Can you reunite the zombie with its brain? Keep that hammer swinging and don't miss. You'll need to crush that brain back into the zombie's cranium before time runs out!</p>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col">
                                            <div className="row justify-content-center">
                                                <div className="col-auto">
                                                    <h6>ATTRACTION INTENSITY</h6>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-auto text-danger">
                                                    <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="row justify-content-center">
                                        <div className="col-auto ">
                                            <img src={AxeLogo} alt="Axe Throwing Attraction Logo" width="150" />
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-4">
                                        <div className="col-12 col-lg-9 text-justify">
                                            <p>How good is your axe throw? Step on up and find out!</p>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col">
                                            <div className="row justify-content-center">
                                                <div className="col-auto">
                                                    <h6>ATTRACTION INTENSITY</h6>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-auto text-danger">
                                                    <FontAwesomeIcon icon="fa-solid fa-skull" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon="fa-regular fa-circle" className="fa fa-icon-normal mx-1"></FontAwesomeIcon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {/* {{!---------------------------------------- END MIDWAY ATTRACTION -------------------------------------------}} */}

        {/* {{!--------------------------------------------------- TICKETS ------------------------------------------------------}} */}
            <div id="tickets" className="anchor"></div>
            <div className="parallax section dividers-red" data-background='bg_stone_red' data-stellar-background-ratio="0.25">
                <div className="section-heading">
                    <h2>
                        TICKETS
                    </h2>
                </div>
                <div className="section-content mt-5">
                    <div className="row my-5">
                        <div className="col">
                            <span className="d-block text-center h4">
                                {/* {{!-- <a href="https://checkout.roller.app/brimstonehaunt/products/generalcheckout" target="_blank" className="btn btn-primary btn-lg py-md-3 px-md-4 text-white">
                                    <FontAwesomeIcon icon="fa-solid fa-ticket-alt" className="fa fa-icon-normal me-2"></span> Purchase <FontAwesomeIcon icon="fa-solid fa-ticket-alt" className="fa fa-icon-normal ms-2"></span>
                                </a> --}} */}
                                {/* {{!-- <button onClick="RollerCheckout.show()" className="btn btn-primary btn-lg py-md-3 px-md-4 text-white">
                                    <FontAwesomeIcon icon="fa-solid fa-ticket-alt" className="fa fa-icon-normal me-2"></span> Purchase <FontAwesomeIcon icon="fa-solid fa-ticket-alt" className="fa fa-icon-normal ms-2"></span>
                                    <img height="1" width="1" style={{"borderStyle": "none"}} alt="" src="https://insight.adsrvr.org/track/pxl/?adv=briyt8x&ct=0:e1od9fg&fmt=3"/>
                                </button> --}} */}
                            </span>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col">
                            <span className="d-block text-center h5">
                                Parking costs $3 <em style={{"fontWeight": "bold"}}>CASH</em> at the door.
                            </span>
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col">
                            <span className="d-block text-center h5">
                                Purchase online to save some time. Base ticket prices are $5 cheaper online and you can skip the ticket office line.
                            </span>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        {/* {{!-- Hayride --}} */}
                        <div className="col-10 col-md-6 col-lg-4 col-xl-2 my-3">
                            <div className="ticket-card">
                            <div className="row px-4">
                                <div className="col">
                                    <h4>HAUNTED HAYRIDE</h4>
                                    <div className="divider"></div>
                                </div>
                            </div>
                            <div className="row px-4">
                                <div className="col">
                                    <h5 className="pt-0">Online Price:</h5>
                                    {/* {{!-- <span className="price">$20</span> --}} */}
                                    <span className="price">$$$</span>
                                    <div className="divider"></div>
                                </div>
                            </div>
                            <div className="row px-4">
                                <div className="col">
                                    <span>Suggested age 8+</span>
                                    <div className="divider"></div>
                                </div>
                            </div>
                            <div className="row px-4">
                                <div className="col">
                                    <p>A one mile ride through our haunted cornfield on a tractor-pulled wagon.</p>
                                    <div className="divider"></div>
                                </div>
                            </div>
                            <div className="row px-4">
                                <div className="col">
                                    <span>20 minute ride</span>
                                    <div className="divider"></div>
                                </div>
                            </div>
                            </div>
                        </div>
                        {/* {{!-- BOG --}} */}
                        <div className="col-10 col-md-6 col-lg-4 col-xl-2 my-3">
                            <div className="ticket-card">
                            <div className="row px-4">
                                <div className="col">
                                    <h4>The BOG</h4>
                                    <div className="divider"></div>
                                </div>
                            </div>
                            <div className="row px-4">
                                <div className="col">
                                    <h5 className="pt-0">Online Price:</h5>
                                    {/* {{!-- <span className="price">$18</span> --}} */}
                                    <span className="price">$$$</span>
                                    <div className="divider"></div>
                                </div>
                            </div>
                            <div className="row px-4">
                                <div className="col">
                                    <span>Suggested age 13+</span>
                                    <div className="divider"></div>
                                </div>
                            </div>
                            <div className="row px-4">
                                <div className="col">
                                    <p>A quarter-mile outdoor walk through our mysterious bog. Will you make it out?</p>
                                    <div className="divider"></div>
                                </div>
                            </div>
                            <div className="row px-4">
                                <div className="col">
                                    <span>20 minute outdoor walk</span>
                                    <div className="divider"></div>
                                </div>
                            </div>
                            </div>
                        </div>
                        {/* {{!-- Psychosis --}} */}
                        <div className="col-10 col-md-6 col-lg-4 col-xl-2 my-3">
                            <div className="ticket-card">
                            <div className="row px-4">
                                <div className="col">
                                    <h4>PSYCHOSIS</h4>
                                    <div className="divider"></div>
                                </div>
                            </div>
                            <div className="row px-4">
                                <div className="col">
                                    <h5 className="pt-0">Online Price:</h5>
                                    {/* {{!-- <span className="price">$11</span> --}} */}
                                    <span className="price">$$$</span>
                                    <div className="divider"></div>
                                </div>
                            </div>
                            <div className="row px-4">
                                <div className="col">
                                    <span>Suggested age 13+</span>
                                    <div className="divider"></div>
                                </div>
                            </div>
                            <div className="row px-4">
                                <div className="col">
                                    <p>A high intensity experience full of disturbing scenes that will pull you further from reality.</p>
                                    <div className="divider"></div>
                                </div>
                            </div>
                            <div className="row px-4">
                                <div className="col">
                                    <span>5 - 10 minute indoor walk</span>
                                    <div className="divider"></div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-5">
                        {/* {{!-- Combo --}} */}
                        <div className="col-10 col-md-6 col-lg-4 col-xl-3  my-3">
                            <div className="ticket-card">
                                <div className="row px-4">
                                    <div className="col">
                                        <h4>COMBO TICKET</h4>
                                        <div className="divider"></div>
                                    </div>
                                </div>
                                <div className="row px-4">
                                    <div className="col">
                                        <h5 className="pt-0">Online Price:</h5>
                                        {/* {{!-- <span className="price">$32</span> --}} */}
                                        <span className="price">$$$</span>
                                        <div className="divider"></div>
                                    </div>
                                </div>
                                <div className="row px-4">
                                    <div className="col">
                                        <span>Hayride + Bog + Psychosis</span>
                                        <div className="divider"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {{!-- Fast Pass --}} */}
                        <div className="col-10 col-md-6 col-lg-4 col-xl-3 my-3">
                            <div className="ticket-card">
                                <div className="row px-4">
                                    <div className="col">
                                        <h4>FAST PASS COMBO</h4>
                                        <div className="divider"></div>
                                    </div>
                                </div>
                                <div className="row px-4">
                                    <div className="col">
                                        <h5 className="pt-0">Online Price:</h5>
                                        {/* {{!-- <span className="price">$52</span> --}} */}
                                        <span className="price">$$$</span>
                                        <div className="divider"></div>
                                    </div>
                                </div>
                                <div className="row px-4">
                                    <div className="col">
                                        <span>Hayride + Bog + Psychosis + Fast Pass</span>
                                        <div className="divider"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {{!-- <div className="row my-5">
                        <div className="col">
                            <span className="d-block text-center h4">
                                <img src={RenFest} alt="" width="250px" /><br/><br/>
                                <a href="https://www.bigtickets.com/e/renfestival/2023ORF/" target="_blank" className="btn btn-dark btn-lg text-white">
                                    <FontAwesomeIcon icon="fa-solid fa-ticket-alt" className="fa fa-icon-normal me-2"></span> Renfest + Haunt <FontAwesomeIcon icon="fa-solid fa-ticket-alt" className="fa fa-icon-normal ms-2"></span>
                                    <img height="1" width="1" style={{"borderStyle": "none"}} alt="" src="https://insight.adsrvr.org/track/pxl/?adv=briyt8x&ct=0:e1od9fg&fmt=3"/>
                                </a>
                            </span>
                        </div>
                    </div> --}} */}
                    <div className="row mt-5">
                        <div className="col">
                            <span className="d-block text-center h6">Groups of 10+ people can be purchased online.</span> 
                            <span className="d-block text-center h6">Please contact us at least 48 hours in advance for groups over 50 at <a href="mailto:groups@brimstonehaunt.com" className="subtle-link">groups@brimstonehaunt.com</a>.</span>
                            <span className="d-block text-center h6">Please keep in mind that group rates only apply to the combo ticket</span>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-5">
                        <div className="col-md-4 col-lg-3 col-xl-2 fine-print text-center text-nowrap">
                            <span>Absolutely no refunds</span>
                        </div>
                        <div className="col-8 col-md-4 col-lg-3 col-xl-2 fine-print text-center text-nowrap divider-right divider-left">
                            <a href="/policies#policy4" className="font-weight-bold h6">Disclaimer</a>
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-2 fine-print text-center text-nowrap">
                            <span>Prices are subject to change.</span>
                        </div>
                    </div>
                </div>
            </div>
        {/* {{!------------------------------------------------- END TICKETS ----------------------------------------------------}} */}

        {/* {{!-------------------------------------------------- SCHEDULE ------------------------------------------------------}} */}
            <div id="schedule" className="anchor"></div>
            <div className="section">
                <div className="row no-gutters">
                    <div className="col-lg-6 pr-lg-5">
                        <div className="section-heading">
                            <h2 className="text-lg-right">
                                Schedule
                            </h2>
                        </div>
                        <div className="section-content mt-5">
                            <div className="row justify-content-end text-lg-right">
                                <div className="col-12 col-xl-8">
                                    <ul>
                                        <li className="mb-5">
                                            Open Friday and Saturday nights through October. The ticket office opens at 7:00pm. Attractions will begin running when it is dark out.
                                        </li>
                                        <li>
                                            The ticket office closes at 11:30pm. The Hayride queue line closes at 11:45. All other attractions will run until lines are empty.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 d-none d-md-block">
                        <img src={CalendarHorz} alt="Season Calendar" />
                    </div>
                    <div className="col-lg-6 d-md-none">
                        <img src={CalendarVert} alt="Season Calendar" />
                    </div>
                </div>
            </div>
        {/* {{!------------------------------------------------- END SCHEDULE ---------------------------------------------------}} */}

        {/* {{!------------------------------------------------- DIRECTIONS -----------------------------------------------------}} */}
            <div id="directions" className="anchor"></div>
            <div className="section">
                <div className="row no-gutters">
                    <div className="col-lg-6 order-2 order-lg-1">
                        <iframe id="embeded-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3078.466591997571!2d-83.98506478424625!3d39.503957679481616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x884094d73418c903%3A0x22bede9365cedce0!2s525+Brimstone+Rd%2C+Wilmington%2C+OH+45177!5e0!3m2!1sen!2sus!4v1492628414617" frameBorder="0" allowFullScreen>
                            Your web browser does not support iframes. Please update to a browser with iframe support. We recommend <a href="https://www.google.com/chrome/">Google Chrome</a>
                        </iframe>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2 pl-lg-5">
                        <div className="section-heading">
                            <h2 className="text-lg-left">
                                DIRECTIONS
                            </h2>
                        </div>
                        <div className="section-content mt-5">
                            <div className="row">
                                <div className="col-12 col-xl-8">
                                    <ul>
                                        <li className="mb-5">
                                            <strong>Coming from I-75: </strong>Take Exit 38 (Springboro/Franklin) and head East on State Route 73 for 15 miles then turn left on Brimstone Road.
                                        </li>
                                        <li>
                                            <strong>Coming from I-71: </strong>Take Exit 45 (Waynesville) and head West on State Route 73 for 2 miles then turn right on Brimstone Road.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col">
                                    <a href="http://maps.apple.com/?address=525,Brimstone+Rd,Wilmington,Ohio" target="_blank">
                                        <FontAwesomeIcon icon="fa-solid fa-map-pin" className="fa fa-icon-small"></FontAwesomeIcon> 525 Brimstone Rd, Wilmington, OH 45177
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {/* {{!------------------------------------------------ END DIRECTIONS --------------------------------------------------}} */}

        {/* {{!----------------------------------------------------- FAQ --------------------------------------------------------}} */}
            <div id="faq" className="anchor"></div>
            <div className="parallax section" data-background='bg_stone_gray' data-stellar-background-ratio="0.25">
                <div className="section-heading">
                    <h2>
                        F. A. Q.
                    </h2>
                </div>
                <div className="section-content mt-5">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8 col-xl-6">
                            <div className="faq list-group">
                                {/* {{!-- FAQ 1 --}} */}
                                <div className="list-group-item list-group-item-action" data-target="#faq1">
                                    <span>What time do you open?</span>
                                </div>
                                <div id="faq1" className="list-group-item collapse">
                                    <span>The ticket office, concessions and beer barn, and our Haunt Shop all open at 7:00pm. The attractions will start running once it is dark.</span>
                                </div>
                                {/* {{!-- FAQ 2 --}} */}
                                <div className="list-group-item list-group-item-action" data-target="#faq2">
                                    <span>If I purchase a ticket online, do I need to go to the ticketbooth?</span>
                                </div>
                                <div id="faq2" className="list-group-item collapse">
                                    <span>NO! Go straight to the attraction entrance.</span>
                                </div>
                                {/* {{!-- FAQ 3 --}} */}
                                <div className="list-group-item list-group-item-action" data-target="#faq3">
                                    <span>How scary are the attractions?</span>
                                </div>
                                <div id="faq3" className="list-group-item collapse">
                                    <span>The Hayride can be enjoyed by the whole family. It is a low-medium intensity haunt but still has its fair share of scares and surprises. The BOG and Psychosis are high intensity haunts with many scares. Zombie Assault is a simulator and features many virtual scares and action sequences. Deadshot is a paintball shooting gallery with zombie theming.</span>
                                </div>
                                {/* {{!-- FAQ 8 --}} */}
                                <div className="list-group-item list-group-item-action" data-target="#faq8">
                                    <span>How long are the lines?</span>
                                </div>
                                <div id="faq8" className="list-group-item collapse">
                                    <span>Wait times vary night to night but we offer a Fast Pass that will significantly reduce wait times. We suggest arriving early as lines build up quickly. Purchasing tickets online will also help you wait less by avoiding ticket window lines.</span>
                                </div>
                                {/* {{!-- FAQ 4 --}} */}
                                <div className="list-group-item list-group-item-action" data-target="#faq4">
                                    <span>How long are the attractions?</span>
                                </div>
                                <div id="faq4" className="list-group-item collapse">
                                    <span>The Hayride is a one mile trail that takes about 20 minutes to ride through. The BOG is a quarter mile walking path that usually takes about 20 minutes. Psychosis is a 5-10 minute indoor walk through. Zombie Assault is about a 5 minute long simulation. Deadshot is a 3-5 minute paint ball shooting gallery.</span>
                                </div>
                                {/* {{!-- FAQ 5 --}} */}
                                <div className="list-group-item list-group-item-action" data-target="#faq5">
                                    <span>What time do you close?</span>
                                </div>
                                <div id="faq5" className="list-group-item collapse">
                                    <span>The ticket office closes at 11:30pm. <b>The Hayride queue line closes at 11:45.</b> The hayride will run until everyone in queue prior to 11:45pm has gone. All other attractions will run until queue lines are empty.</span>
                                </div>
                                {/* {{!-- FAQ 6 --}} */}
                                <div className="list-group-item list-group-item-action" data-target="#faq6">
                                    <span>Do you close for weather?</span>
                                </div>
                                <div id="faq6" className="list-group-item collapse">
                                    <span>Yes if the weather is bad there is a chance we will have to close since our attractions are outdoors. You can check our social media for announcements related to closings.</span>
                                </div>
                                {/* {{!-- FAQ 7 --}} */}
                                {/* {{!-- <div className="list-group-item list-group-item-action" data-target="#faq7">
                                    <span>Is my ticket only good the night I purchase it?</span>
                                </div>
                                <div id="faq7" className="list-group-item collapse">
                                    <span>No. Tickets are good for any night during the season in which they were purchased.</span>
                                </div> --}} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {/* {{!--------------------------------------------------- END FAQ ------------------------------------------------------}} */}

        {/* {{!--------------------------------------------------- SPONSORS -----------------------------------------------------}} */}
            <div className="parallax section" data-background='bg_scratchy_gray' data-stellar-background-ratio="0.25">
                <div className="section-heading">
                    <h2>
                        PARTNERS
                    </h2>
                </div>
                <div className="section-content mt-5 text-center">
                    <div className="row justify-content-center">
                        <div className="col-4 col-lg-3 col-xl-2 my-auto">
                            <a href="http://www.renfestival.com" target="_blank"><img src={RenFest} /></a>
                        </div>
                        <div className="col-4 col-lg-3 col-xl-2 my-auto">
                            <a href="http://www.celticfestohio.com" target="_blank"><img src={CelticFest} /></a>
                        </div>
                        <div className="col-4 col-lg-3 col-xl-2 my-auto">
                            <a href="http://www.1572roadhousebarbq.com" target="_blank"><img src={BBQ} /></a>
                        </div>
                    </div>
                </div>
                <div className="section-heading">
                    <h2>
                        SPONSORS
                    </h2>
                </div>
                <div className="section-content mt-5 text-center">
                    <div className="row justify-content-center">
                        <div className="col-4 col-lg-3 col-xl-2 my-auto">
                            <a href="https://www.mountaindew.com/" target="_blank"><img src={MtnDew} /></a>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-4 col-lg-3 col-xl-2 my-auto">
                            <a href="https://www.guinness.com/en-us" target="_blank"><img src={Guinness} /></a>
                        </div>
                        <div className="col-4 col-lg-3 col-xl-2 my-auto">
                            <a href="http://www.bostonbeer.com/" target="_blank"><img src={TwistedTea} /></a>
                        </div>
                        <div className="col-4 col-lg-3 col-xl-2 my-auto">
                            <a href="http://sonderbrewing.com/" target="_blank"><img src={Sonder} /></a>
                        </div>
                    </div>
                </div>
            </div>
        {/* {{!------------------------------------------------- END SPONSORS ---------------------------------------------------}} */}

        {/* {{!---------------------------------------------------- SOCIAL ------------------------------------------------------}} */}
            <div className="section">
                <div className="section-content">
                    <div id="social-links" className="row justify-content-center">
                        <div className="col-12 col-lg-10 col-xl-8">
                            <div className="row justify-content-center">
                                {/* <!-- LARGE VERSION --> */}
                                <div className="col-4 text-center pt-5 d-none d-md-inline-block">
                                    <a href="http://www.facebook.com/brimstonehaunt" target="_blank">
                                        <span className="fa fa-icon-huge fa-facebook-f icon-zoom icon-circle">
                                            <FontAwesomeIcon icon="fa-brands fa-facebook-f" className="fa fa-icon-huge my-auto"></FontAwesomeIcon>
                                        </span>
                                        <br/><br/><span className="prompt">LIKE US</span>
                                    </a>
                                </div>
                                <div className="col-4 text-center pt-5 d-none d-md-inline-block">
                                    <a href="http://www.instagram.com/brimstonehaunt" target="_blank">
                                        <span className="fa fa-icon-huge fa-instagram icon-zoom icon-circle">
                                            <FontAwesomeIcon icon="fa-brands fa-instagram" className="fa fa-icon-huge my-auto"></FontAwesomeIcon>
                                        </span>
                                        <br/><br/><span className="prompt">FOLLOW US</span>
                                    </a>
                                </div>
                                {/* <!-- SMALL VERSION --> */}
                                <div className="col-4 text-center pt-5 d-inline-block d-md-none">
                                    <a href="http://www.facebook.com/brimstonehaunt" target="_blank">
                                        <span className="fa fa-icon-normal fa-facebook-f icon-zoom icon-circle">
                                            <FontAwesomeIcon icon="fa-brands fa-facebook-f" className="fa fa-icon-normal my-auto"></FontAwesomeIcon>
                                        </span>
                                    </a>
                                </div>
                                <div className="col-4 text-center pt-5 d-inline-block d-md-none">
                                    <a href="http://www.instagram.com/brimstonehaunt" target="_blank">
                                        <span className="fa fa-icon-normal fa-instagram icon-zoom icon-circle">
                                            <FontAwesomeIcon icon="fa-brands fa-instagram" className="fa fa-icon-normal my-auto"></FontAwesomeIcon>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {/* {{!-------------------------------------------------- END SOCIAL ----------------------------------------------------}} */}
        </Container>
    </>);
}