export default function usePublic() {
    return {
        loadConfig: function(cb) {
            fetch('/api/publicConfig', { method: "GET" }).then(
                (res) => {
                    if(!res.ok) {
                        throw new Error(res.status + " " + res.statusText);
                    } else {
                        return res.json();
                    }
                }
            ).then(
                (result) => {
                    cb(result);
                },
                (error) => {
                    console.log(error);
                    alert("We're sorry. Something went wrong. Please try again later.");
                }
            );
        }
    };
}

