import * as React from 'react';
import {
    Row, Col, Form, FloatingLabel, Button
} from 'react-bootstrap';
import { useAlert } from '../../../_useAlert';
import useAuth from '../../../_useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Events() {
    const { alert, confirm } = useAlert();
    const { curUser, curFest } = useAuth();
    const [editEvents, setEditEvents] = React.useState([]);

    const refreshEvents = React.useCallback(() => {
        fetch('/api/events?' + new URLSearchParams({"filter": JSON.stringify({"where": {"festivalId": curFest.id}})}), { method: "GET" }).then(
            (res) => {
                if(!res.ok) {
                    throw new Error(res.status + " " + res.statusText);
                } else {
                    return res.json();
                }
            }
        ).then(
            (result) => {
                setEditEvents(result);
            },
            (error) => {
                console.log(error);
                alert("Something went wrong",
                    <Row>
                        <Col>
                            Unable to load events. Please refresh the page or try again later.
                        </Col>
                    </Row>,
                    () => {}
                );
            }
        );
    }, [alert, curFest.id]);

    React.useEffect(() => {
        refreshEvents();
    }, [refreshEvents]);

    function handleInput(e) {
        var index = e.target.dataset.index;
        var key = e.target.getAttribute('name');
        var val = e.target.value;

        var temp = [...editEvents];
        temp[index] = {...editEvents[index], [key]: val};

        setEditEvents(temp);
    }

    function saveEvents(e) {
        e.preventDefault();

        var promises = [];

        editEvents.forEach(function(event, index) {
            promises.push(
                fetch('/api/events/' + event.id, { 
                    method: "PATCH" ,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "name": event.name
                    })
                }).then(
                    (res) => {
                        if(!res.ok) {
                            throw new Error(res.status + " " + res.statusText);
                        } else {
                            return true;
                        }
                    }
                ).then(
                    (success) => {},
                    (error) => { console.log(error); }
                )
            );
        });

        Promise.all(promises).then(function() {
            alert("Success",
                <Row>
                    <Col>
                        Events have been updated.
                    </Col>
                </Row>,
                () => { refreshEvents(); }
            );
        }).catch(function () {
            alert("Something went wrong",
                <Row>
                    <Col>
                        Unable to update events. Please refresh the page or try again later.
                    </Col>
                </Row>,
                () => {}
            );
        });
    }

    function addEvent() {
        confirm("New Event",
            <>
                <Row className="my-3">
                    <Col>
                        <FloatingLabel controlId={"newEventName"} label="Name">
                            <Form.Control type="text" name="name" placeholder="Name" />
                        </FloatingLabel>
                    </Col>
                </Row>
            </>,
            () => {
                var newEventName = document.getElementById("newEventName").value;

                if(newEventName) {
                    fetch('/api/events/', { 
                        method: "POST" ,
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "name": newEventName,
                            "festivalId": curFest.id
                        })
                    }).then(
                        (res) => {
                            if(!res.ok) {
                                throw new Error(res.status + " " + res.statusText);
                            } else {
                                return true;
                            }
                        }
                    ).then(
                        (success) => {refreshEvents();},
                        (error) => { console.log(error); }
                    );
                } else {
                    alert("Something went wrong",
                        <Row>
                            <Col>
                                Unable to create new event. Please refresh the page or try again later.
                            </Col>
                        </Row>,
                        () => {}
                    );
                }
            },
            () => {}
        );
    }

    return (<>
        <Row>
            <Col xs={12}>
                <Row className="my-3">
                    <Col>
                        <h1>Events</h1>
                    </Col>
                    {(curUser.accessLvl >= 3) && 
                        <Col xs="auto">
                            <Button variant="link" className="link-success" onClick={addEvent}>
                                <FontAwesomeIcon icon="fa-regular fa-square-plus" size="2x" />
                            </Button>
                        </Col>
                    }
                </Row>
                <Form onSubmit={saveEvents}>
                    {editEvents.length && editEvents.map(function(event, index) {
                        return (
                            <Row key={index} className="my-3">
                                <Col xs={2}>
                                    <FloatingLabel controlId={event.id + "ID"} label="ID">
                                        <Form.Control type="text" placeholder="ID"value={event.id} disabled />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel controlId={event.id + "name"} label="Name">
                                        <Form.Control required type="text" name="name" placeholder="Name" data-index={index} value={event.name} onChange={handleInput} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        );
                    })}
                    <Row className="justify-content-center my-3">
                        <Col sm={6} md={5} xl={4}>
                            <div className="d-grid">
                                <Button size="lg" type="submit">Save</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    </>);
}